export const commonDataGridContainerStyle = {
  '& .MuiDataGrid-columnHeaders': {
    background: '#F9F9F9'
  },
  width: '100%',
  height: '100%'
};

export const gridToolbarContainerStyle = {
  display: 'flex',
  justifyContent: 'space-between'
};

export const defaultGridToolbarStyle = {
  gap: 2
};

export const gridToolbarStyle = {
  display: 'flex',
  p: 1,
  height: { xs: '32px', sm: '32px', md: '42px', lg: '52px' }
};

export const gridToolbarButtonsStyle = {
  display: 'flex',
  gap: 2
};

export const gridToolbarOutsideContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0.5rem',
  gap: { xs: '0', sm: '0.5', md: '0.3rem', lg: '0.5rem' }
};

export const toolbarIconsStyle = {
  width: { xs: '1.3rem', sm: '1rem', md: '1.1rem', lg: '1.5rem' },
  height: { xs: '1.3rem', sm: '1rem', md: '1.1rem', lg: '1.5rem' },
  mr: { xs: '0.1rem', sm: '0.1rem', md: '0.1rem', lg: '0.2rem' }
};

export const toolbarButtonStyle = {
  fontSize: { xs: '0rem', sm: '0.61rem', md: '0.7rem', lg: '0.875rem' },
  py: { xs: '4px', sm: '4px', md: '4px', lg: '6px' },
  px: { xs: '6px', sm: '6px', md: '6px', lg: '8px' },
  minWidth: 0
};
