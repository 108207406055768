import {
  Box,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  SxProps,
  Typography
} from '@mui/material';
import { BarChart, BarSeriesType, cheerfulFiestaPalette } from '@mui/x-charts';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useDashboardController } from 'api/controllers/DashboardController';

import { useTranslations } from 'context/TranslationContext';

import { useMonthSelector } from 'hooks/useMonthSelector';

import {
  CHARTS_LABEL_MAX_CHARS,
  CHARTS_LABEL_MAX_CHARS_WITHOUT_ELIPSIS,
  DATE_FORMATS,
  MONTHS
} from 'utils/constants/constants';
import { formatMoney } from 'utils/helpers/moneyHelper';
import { mapHighlightToItems } from 'utils/mappers/charts';

import { chartTitle, monthSelector } from 'styles/components/Charts';

interface BudgetVsActualMonthlyProps {
  sx?: SxProps;
}

export const BudgetVsActualMonthly = ({ sx }: BudgetVsActualMonthlyProps) => {
  const { getBudgetByCompany } = useDashboardController();
  const { translate } = useTranslations();
  const { companyId } = useParams();
  const { selectedMonth, setSelectedMonth, currentYear } = useMonthSelector();

  const [budgetsData, setBudgetsData] = useState(null);

  const fetchBudgets = useCallback(async () => {
    const response = await getBudgetByCompany(
      Number(companyId),
      currentYear,
      selectedMonth
    );

    setBudgetsData(response.budgets || []);
  }, [companyId, selectedMonth, getBudgetByCompany]);

  const handleMonthChange = (event: SelectChangeEvent<number>) => {
    setSelectedMonth(event.target.value as number);
  };

  useEffect(() => {
    fetchBudgets();
  }, [fetchBudgets]);

  return (
    <Paper elevation={4} sx={sx}>
      <Stack height="100%">
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Stack justifyContent="center">
            <Typography sx={chartTitle} align="left">
              {`${translate('labels.budgetVsActualPerMonth')}`}
            </Typography>
          </Stack>
          <Stack justifyContent="center" sx={{ mr: 2, mt: 2 }}>
            <Select
              value={selectedMonth}
              size="small"
              sx={monthSelector}
              onChange={handleMonthChange}
            >
              {MONTHS.map((month) => (
                <MenuItem key={month} value={month}>
                  {moment()
                    .month(month - 1)
                    .format(DATE_FORMATS.monthTextFormat)}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Box>
        <Box height="90%">
          <BarChart
            colors={cheerfulFiestaPalette}
            loading={!budgetsData}
            dataset={budgetsData || []}
            slotProps={{
              legend: {
                itemGap: 25,
                markGap: 3,
                itemMarkWidth: 10,
                itemMarkHeight: 10,
                labelStyle: {
                  fontSize: 12
                }
              },
              loadingOverlay: {
                message: translate('labels.loading')
              },
              noDataOverlay: {
                message: translate('labels.noData')
              }
            }}
            margin={{ left: 80, bottom: 90 }}
            series={
              mapHighlightToItems([
                {
                  dataKey: 'budget',
                  label: translate('labels.budget'),
                  valueFormatter: (value: number) => formatMoney(value)
                },
                {
                  dataKey: 'actual',
                  label: translate('labels.actual'),
                  valueFormatter: (value: number) => formatMoney(value)
                }
              ]) as unknown as BarSeriesType[]
            }
            xAxis={[
              {
                scaleType: 'band',
                dataKey: 'label',
                valueFormatter: (value: string, context: any) =>
                  context.location === 'tick' &&
                  value.length > CHARTS_LABEL_MAX_CHARS_WITHOUT_ELIPSIS
                    ? `${value.slice(0, CHARTS_LABEL_MAX_CHARS)}...`
                    : value,
                tickLabelStyle: {
                  angle: -45,
                  dominantBaseline: 'auto',
                  textAnchor: 'end',
                  fontSize: 10
                }
              }
            ]}
            yAxis={[
              {
                tickLabelStyle: {
                  fontSize: 10
                }
              }
            ]}
          />
        </Box>
      </Stack>
    </Paper>
  );
};
