import {
  CircularProgress,
  Paper,
  Box,
  Typography,
  Stack,
  SxProps,
  Switch,
  FormControlLabel
} from '@mui/material';
import { DataGrid, GridRowSelectionModel } from '@mui/x-data-grid';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { useDashboardController } from 'api/controllers/DashboardController';

import { DocumentSummaryAction } from 'openapi';

import { useTranslations } from 'context/TranslationContext';

import { getDocumentInfoColumns } from 'utils/helpers/documentInfoHelpers';

import { dashboardGridTitle } from 'styles/components/Charts';
import { commonDataGridContainerStyle } from 'styles/components/DataGridStyle';
import { invoicesDataGrid } from 'styles/components/InvoicesDataGridStyle';

interface DocumentsInfoGridProps {
  isOnAllCompanies: boolean;
  sx?: SxProps;
}

export const DocumentsInfoGrid = ({
  isOnAllCompanies,
  sx
}: DocumentsInfoGridProps) => {
  const { companyId } = useParams();
  const location = useLocation();
  const { translate } = useTranslations();

  const { getDocumentSummaryInfo } = useDashboardController();

  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
  const [documentActions, setDocumentActions] =
    useState<DocumentSummaryAction[]>();
  const [showValues, setShowValues] = useState<boolean>(() => {
    const savedShowValues = localStorage.getItem('showValues');
    return savedShowValues ? JSON.parse(savedShowValues) : true;
  });

  const getDocumentSummary = useCallback(async () => {
    try {
      const response = await getDocumentSummaryInfo(
        isOnAllCompanies ? undefined : Number(companyId)
      );
      if (response?.actions) {
        setDocumentActions(response.actions);
      } else {
        setDocumentActions([]);
      }
    } catch (error) {
      setDocumentActions([]);
    }
  }, [companyId, getDocumentSummaryInfo]);

  useEffect(() => {
    getDocumentSummary();
  }, [location, getDocumentSummary]);

  const handleToggleShowValues = () => {
    setShowValues((prev) => {
      const newValue = !prev;
      localStorage.setItem('showValues', JSON.stringify(newValue));
      return newValue;
    });
  };

  const shouldShowNoDataLabel = useMemo(() => {
    if (showValues) {
      return documentActions?.every(
        (action) => !action.values || !action.values[3].value
      );
    }

    return documentActions?.every(
      (action) => !action.values || !action.values[3].count
    );
  }, [documentActions, showValues]);

  return (
    <Paper elevation={4} sx={{ ...commonDataGridContainerStyle, ...sx }}>
      <Box display="flex" flexDirection="column" height="100%">
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingY: 2
          }}
        >
          <Typography sx={dashboardGridTitle} align="left">
            {translate('buttons.invoices')}
          </Typography>
          <Box
            sx={{
              '.MuiSwitch-switchBase': { color: 'primary.main' },
              '.MuiSwitch-track': {
                backgroundColor: '#078dee'
              }
            }}
          >
            <FormControlLabel
              label=""
              control={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography
                    sx={{
                      fontSize: '0.7rem',
                      width: '3rem',
                      textAlign: 'right',
                      fontWeight: !showValues ? 'bold' : 'normal'
                    }}
                  >
                    {translate('labels.count')}
                  </Typography>
                  <Switch
                    checked={showValues}
                    onChange={handleToggleShowValues}
                    color="primary"
                    size="small"
                  />
                  <Typography
                    sx={{
                      fontSize: '0.7rem',
                      width: '5.5rem',
                      textAlign: 'left',
                      fontWeight: showValues ? 'bold' : 'normal'
                    }}
                  >
                    {translate('labels.totalAmount')}
                  </Typography>
                </Box>
              }
            />
          </Box>
        </Box>

        <Box flex={1} overflow="auto">
          {documentActions ? (
            <DataGrid
              columns={getDocumentInfoColumns(translate, showValues)}
              getRowId={(row) => row.action}
              rows={shouldShowNoDataLabel ? [] : documentActions}
              rowHeight={30}
              sx={{
                ...invoicesDataGrid,
                '& .MuiDataGrid-columnHeaders, & .MuiDataGrid-cell': {
                  cursor: 'default'
                },
                fontSize: '0.6rem',
                '& .MuiDataGrid-columnHeaders': {
                  fontSize: '0.6rem',
                  minHeight: '0 !important',
                  height: '35px'
                },
                '& .MuiDataGrid-cell': {
                  padding: '4px'
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'bold'
                },
                '& .boldCell': {
                  fontWeight: 'bold'
                }
              }}
              disableRowSelectionOnClick
              pageSizeOptions={[]}
              hideFooter
              rowSelectionModel={selectedRows}
              onRowSelectionModelChange={setSelectedRows}
              localeText={{
                noRowsLabel: translate('labels.noDocumentData')
              }}
            />
          ) : (
            <Stack alignItems="center" justifyContent="center" height="100%">
              <CircularProgress />
            </Stack>
          )}
        </Box>
      </Box>
    </Paper>
  );
};
