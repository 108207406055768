export const chartTitle = {
  ml: 2.5,
  mb: 0,
  mt: 2,
  fontSize: { xs: '0.75rem', sm: '1.125rem', md: '1.125rem' },
  fontWeight: 'bold'
};

export const dashboardGridTitle = {
  ml: 2.5,
  mb: 0,
  fontSize: { xs: '0.75rem', sm: '1.125rem', md: '1.125rem' },
  fontWeight: 'bold'
};

export const yearSelector = {
  fontSize: { xs: '0.625rem', sm: '0.75rem', md: '0.875rem' },
  ml: 0.5
};

export const monthSelector = {
  fontSize: {
    xs: '0.625rem',
    sm: '0.75rem',
    md: '0.875rem'
  },
  ml: 0.5,
  width: { xs: '7rem', sm: '8rem', md: '8.5rem' }
};
