import { ChevronLeft, ChevronRight, ManageAccounts } from '@mui/icons-material';
import {
  Box,
  Button,
  Drawer,
  Link,
  List,
  ListItem,
  ThemeProvider
} from '@mui/material';
import React, { useCallback, useEffect, useMemo } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import { useCompanyController } from 'api/controllers/CompanyController';

import { Company } from 'openapi';

import { CompanySelect } from 'components/Sidebar/CompanySelect';

import { useCompanies } from 'context/CompanyContext';
import { useTranslations } from 'context/TranslationContext';

import { useRouteSecurity } from 'hooks/useRouteSecurity';

import { COMPANY_PARAMETER } from 'utils/constants/constants';
import { isAuthenticated } from 'utils/helpers/authHelper';
import { AppRoutesEnum } from 'utils/routes';

import { linkStyle, logo as logoStyle } from 'styles/components/HeaderStyle';
import {
  sidebarMainList,
  sidebarLightTheme,
  sidebarIconStyleSelected,
  sidebarWrapper,
  sidebarOpen,
  sidebarClosed,
  sidebarExpandButton,
  sidebarButtonExpanded,
  sidebarButtonCollapsed
} from 'styles/components/SidebarStyle';

import { SidebarTab } from './SidebarTab';
import { SidebarTabsList } from './SidebarTabsList';

interface SidebarProps {
  isMobile: boolean;
  isSidebarOpen: boolean;
  isSidebarExpanded: boolean;
  onToggleSidebar: () => void;
}

export const Sidebar: React.FC<SidebarProps> = ({
  isMobile,
  isSidebarOpen,
  isSidebarExpanded,
  onToggleSidebar
}) => {
  const location = useLocation();
  const { translate } = useTranslations();
  const { getAllCompanies } = useCompanyController();
  const { companiesList, setCompaniesList } = useCompanies();
  const { getHomePageRoute } = useRouteSecurity();
  const navigate = useNavigate();
  const { checkRoutePermission } = useRouteSecurity();

  const sidebarTheme = sidebarLightTheme;

  const currentCompanyId = useMemo(() => {
    const companyId = location.pathname.split('/')[2];
    return Number.isNaN(Number(companyId)) ? '' : companyId;
  }, [location.pathname]);

  const isUserManagementPage = useMemo(() => {
    return (
      location.pathname === AppRoutesEnum.USER_MANAGEMENT ||
      location.pathname.replace(currentCompanyId, COMPANY_PARAMETER) ===
        AppRoutesEnum.COMPANY_USER_MANAGEMENT
    );
  }, [location, currentCompanyId]);

  const isUserManagementVisible = useMemo(
    () =>
      (checkRoutePermission(AppRoutesEnum.USER_MANAGEMENT) ||
        checkRoutePermission(AppRoutesEnum.COMPANY_USER_MANAGEMENT)) &&
      !!companiesList.length,
    [checkRoutePermission, companiesList]
  );

  const navigateUserManagement = useCallback(() => {
    navigate(
      currentCompanyId
        ? AppRoutesEnum.COMPANY_USER_MANAGEMENT.replace(
            COMPANY_PARAMETER,
            currentCompanyId
          )
        : AppRoutesEnum.USER_MANAGEMENT
    );
    if (isMobile) {
      onToggleSidebar();
    }
  }, [navigate, currentCompanyId, isMobile, onToggleSidebar]);

  const fetchCompanies = useCallback(async () => {
    const allCompanies = await getAllCompanies();
    const sortedCompanies = allCompanies.sort(
      (a: Company, b: Company) => (a.id ?? 0) - (b.id ?? 0)
    );
    setCompaniesList([...sortedCompanies]);
  }, [getAllCompanies, setCompaniesList]);

  useEffect(() => {
    fetchCompanies();
  }, []);

  const handleTabClick = useCallback(() => {
    if (isMobile) {
      onToggleSidebar();
    }
  }, [isMobile, onToggleSidebar]);

  const sidebarContent = (
    <>
      {!isMobile && (
        <Button
          sx={{
            ...sidebarExpandButton,
            ...(isSidebarExpanded
              ? sidebarButtonExpanded
              : sidebarButtonCollapsed)
          }}
          onClick={onToggleSidebar}
        >
          {isSidebarExpanded ? <ChevronLeft /> : <ChevronRight />}
        </Button>
      )}

      <List sx={sidebarMainList}>
        <ListItem sx={logoStyle}>
          <Link component={NavLink} to={getHomePageRoute()} sx={linkStyle}>
            {isSidebarExpanded ? (
              <img
                className="logo-with-text"
                src="/logoWithText.png"
                alt="ExpensePilot"
              />
            ) : (
              <img className="logo" src="/favicon.png" alt="ExpensePilot" />
            )}
          </Link>
        </ListItem>

        <CompanySelect
          companiesList={companiesList}
          isSidebarExpanded={isSidebarExpanded}
          currentCompanyId={currentCompanyId}
        />

        {location.pathname !== AppRoutesEnum.ADD_COMPANY && (
          <SidebarTabsList
            currentCompanyId={currentCompanyId}
            isSidebarExpanded={isSidebarExpanded}
            onTabClick={handleTabClick}
          />
        )}
      </List>

      {isUserManagementVisible && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            height: '100%'
          }}
        >
          <Box>
            <SidebarTab
              isSidebarExpanded={isSidebarExpanded}
              isSelected={isUserManagementPage}
              onClick={navigateUserManagement}
              text={translate('labels.userManagement')}
              tabIcon={
                <ManageAccounts
                  sx={isUserManagementPage ? sidebarIconStyleSelected : {}}
                />
              }
            />
          </Box>
        </Box>
      )}
    </>
  );

  if (!isAuthenticated()) {
    return null;
  }

  return (
    <ThemeProvider theme={sidebarTheme}>
      {isMobile ? (
        <Drawer
          variant="temporary"
          open={isSidebarOpen}
          onClose={onToggleSidebar}
          PaperProps={{
            sx: {
              width: '290px'
            }
          }}
          ModalProps={{
            keepMounted: true
          }}
        >
          <Box sx={sidebarWrapper}>{sidebarContent}</Box>
        </Drawer>
      ) : (
        <Box
          sx={{
            ...sidebarWrapper,
            ...(isSidebarExpanded ? sidebarOpen : sidebarClosed)
          }}
        >
          {sidebarContent}
        </Box>
      )}
    </ThemeProvider>
  );
};
