/* eslint-disable react/no-array-index-key */
/* eslint-disable no-control-regex */
/* eslint-disable consistent-return */
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import LinkOffOutlinedIcon from '@mui/icons-material/LinkOffOutlined';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {
  Box,
  Button,
  TextField,
  FormHelperText,
  IconButton,
  Typography,
  Switch,
  Tooltip,
  Divider,
  Skeleton
} from '@mui/material';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { useIbanController } from 'api/controllers/IbanController';
import { useOpenBankingController } from 'api/controllers/OpenBankingController';

import { Company, CompanyIban, Iban } from 'openapi';

import { OverlayLoading } from 'components/overlays/OverlayLoading';
import { ConfirmationDialog } from 'components/shared/Modal/ConfirmationDialog';
import { Modal } from 'components/shared/Modal/Modal';

import { useTranslations } from 'context/TranslationContext';

import { useModal } from 'hooks/useModal';

import {
  INITIAL_IBAN_ITEM,
  COUNTERPARTY_IBAN_ADDITIONAL_INITIAL_FIELDS,
  POLLING_INTERVAL,
  POLLING_TIMEOUT
} from 'utils/constants/constants';
import { CompanyTabs } from 'utils/enums/Company';
import { uniqueIbanValidation } from 'utils/helpers/ibanHelper';
import { validateIban } from 'utils/helpers/invoiceHelpers';
import { formatMoney } from 'utils/helpers/moneyHelper';
import { AppRoutesEnum } from 'utils/routes';

import { arrayFieldFormContainer } from 'styles/components/Common';
import {
  verificationFormFieldsWrapper,
  smallVerificationFieldWidth,
  verificationFormFields,
  verificationFormItemNoHeight
} from 'styles/pages/InvoiceVerificationStyle';

interface CompanyIbanWithTmpId extends CompanyIban {
  tmpId: string;
}
interface CompanyIbansProps {
  readonly refreshCompanyDetails: () => void;
  readonly companyDetails?: Company;
}

export const CompanyIbans = ({
  refreshCompanyDetails,
  companyDetails
}: CompanyIbansProps) => {
  const { translate } = useTranslations();
  const { companyId } = useParams();
  const {
    openModal: openDeleteIbanModal,
    closeModal: closeDeleteIbanModal,
    isOpen: isDeleteIbanModalOpen
  } = useModal();
  const {
    openModal: openSignOutModal,
    closeModal: closeSignOutModal,
    isOpen: isSignOutModalOpen
  } = useModal();
  const {
    openModal: openConsentFailModal,
    closeModal: closeConsentFailModal,
    isOpen: isConsentFailModalOpen
  } = useModal();
  const {
    getIbansByCompany,
    createCompanyIbanByCompanyId,
    deleteCompanyIbanByCompanyId,
    getCompanyIbanByCompanyId
  } = useIbanController();

  const {
    consentIbanByCompanyId,
    deleteIbanByCompanyId,
    signUpToOpenBanking,
    signOutFromOpenBanking
  } = useOpenBankingController();

  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [usernameHasError, setUsernameHasError] = useState(false);
  const [modalStates, setModalStates] = useState<Record<string, boolean>>({});
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [selectedIbanForDeletion, setSelectedIbanForDeletion] =
    useState<number>(0);
  const [selectedIbanForRemoveConsent, setSelectedIbanForRemoveConsent] =
    useState<CompanyIban | null>(null);
  const [searchParams] = useSearchParams();
  const [areIbansLoading, setAreIbansLoading] = useState(
    !(searchParams.get('status') === 'success')
  );
  const [isWaitingForConsent, setIsWaitingForConsent] = useState(
    searchParams.get('status') === 'success'
  );
  const [isInitialIbansRequestCompleted, setIsInitialIbansRequestCompleted] =
    useState(false);

  const {
    control,
    formState,
    getValues,
    setValue,
    watch,
    formState: { errors },
    trigger
  } = useForm<{ ibanList: CompanyIbanWithTmpId[] }>({
    mode: 'onChange',
    defaultValues: {
      ibanList: [
        {
          ...INITIAL_IBAN_ITEM,
          ...COUNTERPARTY_IBAN_ADDITIONAL_INITIAL_FIELDS,
          tmpId: uuidv4()
        } as Iban
      ]
    }
  });

  const { append, remove } = useFieldArray({
    control,
    name: 'ibanList',
    keyName: 'fieldId'
  });

  const watchedIbanList = watch('ibanList');

  const toggleModal = (tmpId: string, isOpen: boolean) => {
    setModalStates((prevState) => ({
      ...prevState,
      [tmpId]: isOpen
    }));
  };

  const getIbans = async () => {
    let result = [];
    try {
      result = await getIbansByCompany(Number(companyId));
    } finally {
      setAreIbansLoading(false);
      setIsInitialIbansRequestCompleted(true);
    }
    if (!result.length) {
      return;
    }
    setValue(
      'ibanList',
      result.map((item: Iban) => ({ ...item, tmpId: uuidv4() }))
    );
  };

  useEffect(() => {
    getIbans();
  }, []);

  const appendIban = () => {
    append({
      ...INITIAL_IBAN_ITEM,
      ...COUNTERPARTY_IBAN_ADDITIONAL_INITIAL_FIELDS,
      // @ts-ignore tmpId is used for unique identification for each IBAN (even newly created ones)
      tmpId: uuidv4()
    });
  };

  const removeIban = (index: number) => {
    remove(index);
    if (watchedIbanList && watchedIbanList[index].id) {
      deleteCompanyIbanByCompanyId(
        Number(companyId),
        watchedIbanList[index].id
      );
    }
    trigger();
    if (watchedIbanList?.length === 1) {
      appendIban();
    }
  };

  const handleConsentToIris = useCallback(
    async (item: CompanyIban) => {
      try {
        const consentUrl = await consentIbanByCompanyId(
          Number(companyId),
          Number(item.id),
          {
            psuId: username
          }
        );
        if (consentUrl) {
          window.location.href = consentUrl.url;
        }
      } finally {
        setIsRedirecting(false);
      }
    },
    [companyId, consentIbanByCompanyId, username]
  );

  const handleSignUp = useCallback(async () => {
    await signUpToOpenBanking(Number(companyId));
    refreshCompanyDetails();
    getIbans();
  }, [companyId, signUpToOpenBanking, refreshCompanyDetails]);

  const handleConfirmSignOut = useCallback(async () => {
    closeSignOutModal();
    await signOutFromOpenBanking(Number(companyId));
    refreshCompanyDetails();
    getIbans();
  }, [companyId, signOutFromOpenBanking, refreshCompanyDetails]);

  const handleConfirmDeleteIban = () => {
    removeIban(selectedIbanForDeletion);
    closeDeleteIbanModal();
  };

  const handleConfirmRemoveConsent = useCallback(async () => {
    if (selectedIbanForRemoveConsent) {
      await deleteIbanByCompanyId(
        Number(companyId),
        Number(selectedIbanForRemoveConsent.id)
      );
      setSelectedIbanForRemoveConsent(null);
      setAreIbansLoading(true);
      getIbans();
    }
  }, [companyId, deleteIbanByCompanyId, selectedIbanForRemoveConsent]);

  const getGrantConsentTooltip = (item: CompanyIban) => {
    if (!companyDetails?.isOpenBankingSignedUp) {
      return translate('messages.openBankingNotEnabled');
    }
    if (!item.id || !item.isOpenBankingSupported) {
      return translate('messages.openBankingNotSupported');
    }
    return '';
  };

  const handleNavigateToDefaultUrl = () => {
    navigate(
      `${AppRoutesEnum.COMPANY_DETAILS.replace(
        ':companyId',
        String(companyId)
      )}?tab=${CompanyTabs.CompanyIbans}`
    );
  };

  const handlePolling = useCallback(async () => {
    const ibanId = searchParams.get('ibanId');
    if (!ibanId || !isInitialIbansRequestCompleted) {
      return;
    }
    const result: CompanyIban = await getCompanyIbanByCompanyId(
      Number(companyId),
      Number(ibanId)
    );
    const currentIban = watchedIbanList.find((item) => item.id === result.id);
    if (
      !isWaitingForConsent ||
      result.consentUpdatedAt === currentIban?.consentUpdatedAt ||
      !result.consentUpdatedAt
    ) {
      return;
    }
    handleNavigateToDefaultUrl();
    setAreIbansLoading(true);
    getIbans();
    setIsWaitingForConsent(false);
  }, [
    companyId,
    getCompanyIbanByCompanyId,
    getIbans,
    handleNavigateToDefaultUrl,
    searchParams,
    watchedIbanList
  ]);

  const handleCloseConsentFailModal = () => {
    closeConsentFailModal();
    handleNavigateToDefaultUrl();
  };

  useEffect(() => {
    if (isWaitingForConsent) {
      const pollingInterval = setInterval(handlePolling, POLLING_INTERVAL);
      const pollingTimeout = setTimeout(() => {
        clearInterval(pollingInterval);
        setIsWaitingForConsent(false);
        openConsentFailModal();
      }, POLLING_TIMEOUT);

      return () => {
        clearInterval(pollingInterval);
        clearTimeout(pollingTimeout);
      };
    }
  }, [isWaitingForConsent, handlePolling]);

  useEffect(() => {
    if (searchParams.get('status') === 'fail') {
      openConsentFailModal();
    }
  }, [searchParams.get('status')]);

  useEffect(() => {
    trigger();
  }, [trigger, formState.isValid]);

  return (
    <Box sx={{ width: '100%' }}>
      {areIbansLoading || isWaitingForConsent ? (
        <Box sx={{ mt: 1 }}>
          <Skeleton
            animation="wave"
            sx={{ mb: 3 }}
            variant="rounded"
            width={430}
            height={22}
          />
          <Divider sx={{ mb: 4, width: '100%' }} />
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3.8 }}>
            {[...Array(3)].map((_, index) => (
              <Box
                key={index}
                sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
              >
                <Skeleton
                  animation="wave"
                  variant="rounded"
                  width={400}
                  height={56}
                />
                <Skeleton
                  animation="wave"
                  variant="circular"
                  width={25}
                  height={25}
                />
                <Skeleton
                  animation="wave"
                  variant="circular"
                  width={25}
                  height={25}
                />
              </Box>
            ))}
          </Box>
        </Box>
      ) : (
        <form className="supplier-form">
          <Box sx={verificationFormFields}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography>{`${translate('labels.enableOpenBankingFor')} ${
                companyDetails?.name
              }`}</Typography>
              <Switch
                checked={!!companyDetails?.isOpenBankingSignedUp}
                onChange={(event, checked) =>
                  checked ? handleSignUp() : openSignOutModal()
                }
              />
            </Box>
            <Divider sx={{ mb: 2, mt: 0, width: '100%' }} />
            <Box
              sx={{
                ...verificationFormFieldsWrapper,
                minHeight: '50px',
                flexDirection: 'column'
              }}
            >
              {watchedIbanList?.map((item: CompanyIbanWithTmpId, index) => {
                const isConsentExpired = item.consentExpirationDate
                  ? !moment(item.consentExpirationDate, 'DD.MM.YYYY').isAfter(
                      moment()
                    )
                  : false;

                return (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      gap: 1
                    }}
                    key={item.tmpId}
                  >
                    <Box
                      sx={{
                        ...arrayFieldFormContainer,
                        width: '50%',
                        alignItems: 'center',
                        maxWidth: '400px'
                      }}
                      // @ts-ignore tmpId is used for unique identification for each IBAN (even newly created ones)
                    >
                      <Box sx={{ ...verificationFormFields, width: '100%' }}>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: 70
                          }}
                        >
                          <Controller
                            name={`ibanList.${index}.iban`}
                            control={control}
                            rules={{
                              validate: (value) =>
                                value
                                  ? validateIban(value) &&
                                    uniqueIbanValidation(
                                      translate,
                                      getValues(`ibanList.${index}.iban`),
                                      getValues('ibanList')
                                    )
                                  : true
                            }}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                disabled={!!item.id}
                                onChange={(e) => {
                                  field.onChange(e.target.value);
                                  trigger();
                                }}
                                onBlur={async (event) => {
                                  if (
                                    validateIban(event.target.value) &&
                                    uniqueIbanValidation(
                                      translate,
                                      getValues(`ibanList.${index}.iban`),
                                      getValues('ibanList')
                                    ) === true
                                  ) {
                                    await createCompanyIbanByCompanyId(
                                      Number(companyId),
                                      { iban: event.target.value.trim() }
                                    );
                                    getIbans();
                                  }
                                }}
                                label={translate('labels.iban')}
                                error={
                                  !!errors.ibanList && !!errors.ibanList[index]
                                }
                                placeholder={translate('labels.iban')}
                                sx={smallVerificationFieldWidth}
                              />
                            )}
                          />
                          {errors.ibanList && errors.ibanList[index] && (
                            <FormHelperText error>
                              {!validateIban(
                                getValues(`ibanList.${index}.iban`) || ''
                              )
                                ? translate('errors.invalidIban')
                                : translate('errors.ibanUnique')}
                            </FormHelperText>
                          )}
                        </Box>
                      </Box>

                      <Modal
                        isOpen={!!modalStates[item.tmpId]}
                        hide={() => toggleModal(item.tmpId, false)}
                        headerTitle={translate('labels.consent')}
                        maxWidth="sm"
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2
                          }}
                        >
                          <Typography sx={{ fontSize: '0.9rem', mb: 1 }}>
                            {translate('messages.consentMessage')}
                          </Typography>
                          <Box
                            sx={{
                              ...verificationFormFieldsWrapper,
                              minHeight: '80px'
                            }}
                          >
                            <TextField
                              label={`${translate('labels.username')}*`}
                              placeholder={`${translate(
                                'labels.onlineBankingUsername'
                              )}*`}
                              sx={verificationFormItemNoHeight}
                              onChange={(e) => {
                                const { value } = e.target;
                                if (/^[\x00-\x7F]*$/.test(value)) {
                                  setUsername(value);
                                  setUsernameHasError(false);
                                } else {
                                  setUsernameHasError(true);
                                }
                              }}
                              onBlur={(e) => {
                                if (!/^[\x00-\x7F]*$/.test(e.target.value)) {
                                  setUsernameHasError(true);
                                }
                              }}
                              error={usernameHasError}
                              helperText={
                                usernameHasError
                                  ? translate('labels.onlyLatinAllowed')
                                  : ''
                              }
                            />
                          </Box>
                          <Button
                            sx={{ alignSelf: 'flex-end' }}
                            disabled={!username || usernameHasError}
                            variant="contained"
                            onClick={() => {
                              handleConsentToIris(item);
                              setIsRedirecting(true);
                              toggleModal(item.tmpId, false);
                            }}
                          >
                            {translate('buttons.continue')}
                          </Button>
                        </Box>
                      </Modal>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 5, mb: 0.5 }}>
                      <Box display="flex">
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            pb: 1.5
                          }}
                        >
                          <Tooltip title={translate('labels.deleteIban')}>
                            <span>
                              <IconButton
                                color="error"
                                type="button"
                                onClick={() => {
                                  setSelectedIbanForDeletion(index);
                                  openDeleteIbanModal();
                                }}
                              >
                                <RemoveCircleOutlineIcon />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            pb: 1.5
                          }}
                        >
                          {item.isOpenBankingConsented && (
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center'
                              }}
                            >
                              <IconButton
                                onClick={() => toggleModal(item.tmpId, true)}
                              >
                                <Tooltip
                                  title={translate('labels.renewConsent')}
                                >
                                  <AssuredWorkloadIcon color="primary" />
                                </Tooltip>
                              </IconButton>
                            </Box>
                          )}
                        </Box>
                        <Tooltip title={getGrantConsentTooltip(item)}>
                          <span>
                            <IconButton
                              sx={{ mt: 1 }}
                              disabled={
                                !companyDetails?.isOpenBankingSignedUp ||
                                !item.id ||
                                !item.isOpenBankingSupported
                              }
                              onClick={() =>
                                item.isOpenBankingConsented
                                  ? setSelectedIbanForRemoveConsent(item)
                                  : toggleModal(item.tmpId, true)
                              }
                            >
                              {item.isOpenBankingConsented ? (
                                <Tooltip
                                  title={translate('labels.revokeConsent')}
                                >
                                  <LinkOffOutlinedIcon color="warning" />
                                </Tooltip>
                              ) : (
                                <Tooltip
                                  title={translate('labels.giveConsent')}
                                >
                                  <AccountBalanceOutlinedIcon
                                    color={
                                      !companyDetails?.isOpenBankingSignedUp ||
                                      !item.id ||
                                      !item.isOpenBankingSupported
                                        ? 'disabled'
                                        : 'primary'
                                    }
                                  />
                                </Tooltip>
                              )}
                            </IconButton>
                          </span>
                        </Tooltip>
                      </Box>

                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          width: '10rem'
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: '600',
                            color: 'rgb(0,0,0, 0.70)'
                          }}
                        >
                          {isConsentExpired && !item.balance
                            ? 'n/a'
                            : item.balance !== null &&
                              item.balance !== undefined &&
                              `${formatMoney(item.balance)} ${item.currency}`}
                        </Typography>

                        <Typography
                          sx={{
                            fontSize: '0.8rem',
                            color: 'rgb(0,0,0, 0.57)'
                          }}
                        >
                          {item.balanceUpdatedAt &&
                            moment(
                              Math.min(
                                item.balanceUpdatedAt,
                                moment().toDate().getTime()
                              )
                            ).fromNow()}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column'
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: '600',
                            color: isConsentExpired
                              ? 'rgb(244, 67, 54, 0.90)'
                              : 'rgb(0,0,0, 0.70)'
                          }}
                        >
                          {item.consentExpirationDate}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: '0.8rem',
                            color: isConsentExpired
                              ? 'rgb(244, 67, 54, 0.90)'
                              : 'rgb(0,0,0, 0.57)',
                            width: '15rem'
                          }}
                        >
                          {item.consentExpirationDate &&
                            (isConsentExpired
                              ? translate('labels.consentExpired')
                              : translate('labels.consentExpiryDate'))}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  maxWidth: '400px'
                }}
              >
                <Button type="button" onClick={appendIban}>
                  {`+ ${translate('buttons.add')}`}
                </Button>
              </Box>
              {isRedirecting && (
                <OverlayLoading
                  message={translate('messages.redirectedToOpenBanking')}
                />
              )}
              <ConfirmationDialog
                isOpen={!!selectedIbanForRemoveConsent}
                onClose={() => setSelectedIbanForRemoveConsent(null)}
                onConfirm={handleConfirmRemoveConsent}
                title={translate('labels.revokeConsent')}
                size="md"
              >
                {translate('messages.removeConsent', {
                  iban: selectedIbanForRemoveConsent?.iban || ''
                })}
              </ConfirmationDialog>
              <ConfirmationDialog
                isOpen={isSignOutModalOpen}
                onClose={closeSignOutModal}
                onConfirm={handleConfirmSignOut}
                title={translate('labels.disableOpenBanking')}
              >
                <Typography sx={{ fontSize: '0.9rem', mb: 1 }}>
                  {translate('messages.disableOpenBanking')}
                </Typography>
              </ConfirmationDialog>
              <Modal
                isOpen={isConsentFailModalOpen}
                hide={handleCloseConsentFailModal}
                headerTitle={translate('labels.consentFailed')}
                maxWidth="sm"
              >
                <Typography sx={{ fontSize: '0.9rem', mb: 1 }}>
                  {translate('messages.consentFailed')}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    onClick={handleCloseConsentFailModal}
                    variant="contained"
                    color="error"
                    sx={{ mt: 2 }}
                  >
                    {translate('buttons.close')}
                  </Button>
                </Box>
              </Modal>
              <ConfirmationDialog
                isOpen={isDeleteIbanModalOpen}
                onClose={closeDeleteIbanModal}
                onConfirm={handleConfirmDeleteIban}
                title={translate('labels.deleteIban')}
                size="md"
              >
                {translate('messages.deleteIbanConfirmation', {
                  iban: watchedIbanList[selectedIbanForDeletion]?.iban
                    ? watchedIbanList[selectedIbanForDeletion].iban
                    : ''
                })}
              </ConfirmationDialog>
            </Box>
          </Box>
        </form>
      )}
    </Box>
  );
};
