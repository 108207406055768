import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import {
  Box,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import { useChangeLogController } from 'api/controllers/ChangeLogController';

import { ChangeLog } from 'openapi';

import { useTranslations } from 'context/TranslationContext';

import { useDataGridHelpers } from 'hooks/useDataGridHelpers';

import { ChangeLogFields, priorityMap } from 'utils/enums/ChangeLog';
import {
  getTranslatedMessageTSX,
  renderValue
} from 'utils/helpers/changeLogHelpers';

interface ChangeLogProps {
  readonly invoiceId: number;
}

export const ChangeLogList = ({ invoiceId }: ChangeLogProps) => {
  const { translate } = useTranslations();
  const { getSeverityColor } = useDataGridHelpers();
  const { getChangeLogs } = useChangeLogController();

  const [changeLogs, setChangeLogs] = useState<ChangeLog[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<ChangeLog | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const getChangeLogsByInvoiceId = useCallback(async () => {
    const response = await getChangeLogs(invoiceId);
    setIsLoading(false);
    const sortedChangeLogs = (response.changesLogs || []).map(
      (log: ChangeLog) => {
        const sortedChanges = (log.changes || []).slice().sort((a, b) => {
          const aPriority = priorityMap[a.field as ChangeLogFields] ?? 999;
          const bPriority = priorityMap[b.field as ChangeLogFields] ?? 999;
          return aPriority - bPriority;
        });

        return {
          ...log,
          changes: sortedChanges.filter((change) => change.field !== 'ID')
        };
      }
    );

    setChangeLogs(sortedChangeLogs);
    setSelectedGroup(sortedChangeLogs[0] || null);
  }, [getChangeLogs, invoiceId]);

  useEffect(() => {
    getChangeLogsByInvoiceId();
  }, [getChangeLogsByInvoiceId]);

  const getTranslatedFieldLabel = (field: ChangeLogFields) => {
    return translate(`fields.${field}`);
  };

  return isLoading ? (
    <Stack alignItems="center" justifyContent="center" height="80vh">
      <CircularProgress />
    </Stack>
  ) : (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '80vh',
        margin: 'auto',
        overflow: 'hidden'
      }}
    >
      {changeLogs.length ? (
        <>
          <Box
            sx={{
              width: '60%',
              borderRight: '1px solid #ccc',
              height: '80vh',
              overflowY: 'auto',
              '.MuiList-root': {
                paddingY: 0
              }
            }}
          >
            <List>
              {changeLogs.map((group) => {
                const firstChange =
                  group.changes?.find((c) => c.field === 'STAGE') ||
                  group.changes?.[0];
                if (!firstChange) return null;

                const listMessage = getTranslatedMessageTSX(
                  (firstChange.field as ChangeLogFields) || '',
                  firstChange.oldValue || '',
                  firstChange.newValue || '',
                  group.user || '',
                  group,
                  translate,
                  getSeverityColor
                );

                return listMessage ? (
                  <ListItem
                    key={`${group.timestamp}-${group.user}`}
                    disablePadding
                    sx={{
                      '.MuiListItemButton-root': {
                        paddingY: '0 !important'
                      },
                      backgroundColor:
                        selectedGroup?.timestamp === group.timestamp
                          ? 'rgba(7, 141, 238, 0.08)'
                          : 'inherit'
                    }}
                  >
                    <ListItemButton onClick={() => setSelectedGroup(group)}>
                      <ListItemText
                        primary={listMessage}
                        secondary={new Date(group.timestamp!).toLocaleString()}
                      />
                    </ListItemButton>
                  </ListItem>
                ) : null;
              })}
            </List>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              width: '40%',
              '.MuiList-padding': {
                paddingTop: '0 !important'
              }
            }}
          >
            {selectedGroup ? (
              <>
                <Typography>
                  <strong>{selectedGroup.user}</strong>{' '}
                  {translate('labels.madeChangesAt')}{' '}
                  {new Date(selectedGroup.timestamp!).toLocaleString()}
                </Typography>
                <Divider />
                <List>
                  {selectedGroup.changes?.map((change) => {
                    const label = getTranslatedFieldLabel(
                      (change.field as ChangeLogFields) || ''
                    );

                    return (
                      <ListItem
                        key={`${selectedGroup.timestamp}-${change.field}-${change.oldValue}-${change.newValue}`}
                        sx={{ p: '0.5rem 0 0' }}
                      >
                        <Typography sx={{ mr: 0.5 }}>{label}:</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          {change.oldValue &&
                            renderValue(
                              change.oldValue,
                              selectedGroup,
                              translate,
                              getSeverityColor
                            )}
                          {change.oldValue && (
                            <ArrowRightAltIcon sx={{ fontSize: '1.2rem' }} />
                          )}
                          {renderValue(
                            change.newValue || '-',
                            selectedGroup,
                            translate,
                            getSeverityColor
                          )}
                        </Box>
                      </ListItem>
                    );
                  })}
                </List>
              </>
            ) : null}
          </Box>
        </>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%'
          }}
        >
          <Typography>{translate('messages.noChangeLogs')}</Typography>
        </Box>
      )}
    </Box>
  );
};
