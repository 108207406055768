import {
  Autocomplete,
  Box,
  Chip,
  createFilterOptions,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Control, Controller } from 'react-hook-form';

import {
  CurrencyDropDown,
  DocumentType,
  Invoice,
  InvoiceExpenseType,
  InvoiceStages,
  PaymentChannel,
  PaymentStatus
} from 'openapi';

import { useTranslations } from 'context/TranslationContext';

import { DATE_FORMATS } from 'utils/constants/constants';
import {
  DOCUMENT_TYPES_TRANSLATION_KEYS,
  PAYMENT_CHANNELS_TRANSLATION_KEYS
} from 'utils/constants/invoices';
import { processMoneyInput } from 'utils/helpers/moneyHelper';
import { InvoiceDetails } from 'utils/interfaces/InvoiceProps';

import {
  verificationFormFields,
  verificationFormFieldsWrapper,
  verificationFormItem,
  verificationFormItemNoHeight
} from 'styles/pages/InvoiceVerificationStyle';

interface EditInvoiceDataFormProps {
  currentInvoice: Invoice;
  control: Control<InvoiceDetails, any>;
  expenseTypeOptions: InvoiceExpenseType[];
  currencies: CurrencyDropDown[];
  availableTags: string[];
  watch: (
    name: string,
    defaultValue?: string | number | undefined
  ) => string | number;
  setAvailableTags: (value: React.SetStateAction<string[]>) => void;
}

export const EditInvoiceDataForm = ({
  currentInvoice,
  control,
  expenseTypeOptions,
  currencies,
  availableTags,
  watch,
  setAvailableTags
}: EditInvoiceDataFormProps) => {
  const { translate } = useTranslations();

  const [isInvoiceDateOpen, setInvoiceDateOpen] = useState(false);
  const [isDueDateOpen, setDueDateOpen] = useState(false);
  const [tags, setTags] = useState<string[]>(currentInvoice.tags || []);

  const filter = createFilterOptions<string>();

  const watchedVatBase = watch('vatBase');
  const watchedVatAmount = watch('vatAmount');
  const watchedInvoiceAmount = watch('invoiceAmount');

  const isInvoiceAmountValid = useMemo(() => {
    if (!watchedInvoiceAmount) {
      return false;
    }

    const total =
      (Number(watchedVatBase) || 0) + (Number(watchedVatAmount) || 0);
    return (
      (Number(watchedInvoiceAmount) || 0).toFixed(2) ===
      Number(total).toFixed(2)
    );
  }, [watchedVatBase, watchedVatAmount, watchedInvoiceAmount]);

  useEffect(() => {
    setAvailableTags((previousTags) =>
      Array.from(new Set([...tags, ...previousTags]))
    );
  }, [tags]);

  return (
    <Box sx={verificationFormFields}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        {currentInvoice.documentType !== DocumentType.NO_DOCUMENT && (
          <Box
            sx={{
              ...verificationFormFieldsWrapper,
              minHeight: '50px',
              flexDirection: { xs: 'column', sm: 'row' },
              gap: { xs: 3, sm: 2 },
              mb: { xs: 1, sm: 0 }
            }}
          >
            <FormControl sx={verificationFormItem}>
              <Controller
                name="invoiceNumber"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={!!error}
                    label={`${translate('labels.invoiceNumber')}*`}
                    name="invoiceNumber"
                    placeholder={translate('labels.invoiceNumber')}
                    sx={verificationFormItem}
                    helperText={error && translate('errors.invoiceNumber')}
                  />
                )}
              />
            </FormControl>

            <Controller
              name="invoiceDate"
              control={control}
              rules={{ required: true }}
              render={({
                field: { onChange, value },
                fieldState: { error }
              }) => {
                return (
                  <FormControl error={!!error} sx={verificationFormItem}>
                    <DatePicker
                      sx={{ width: '100%' }}
                      format={DATE_FORMATS.displayedDateFormat}
                      open={isInvoiceDateOpen}
                      disableFuture
                      label={`${translate('labels.invoiceDate')}*`}
                      value={
                        value
                          ? moment(value, DATE_FORMATS.displayedDateFormat)
                          : null
                      }
                      onClose={() => setInvoiceDateOpen(false)}
                      onChange={(event) => {
                        onChange(
                          (event as Moment).format(
                            DATE_FORMATS.displayedDateFormat
                          )
                        );
                      }}
                      slotProps={{
                        textField: {
                          error: !!error,
                          helperText:
                            !!error && translate('errors.invoiceDate'),
                          name: 'invoiceDate',
                          onClick: () => setInvoiceDateOpen(true),
                          inputProps: { readOnly: true }
                        },
                        openPickerButton: {
                          onClick: () => setInvoiceDateOpen(true)
                        }
                      }}
                    />
                  </FormControl>
                );
              }}
            />

            <Controller
              name="dueDate"
              rules={{ required: true }}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error }
              }) => {
                return (
                  <FormControl error={!!error} sx={verificationFormItem}>
                    <DatePicker
                      sx={{ width: '100%' }}
                      format={DATE_FORMATS.displayedDateFormat}
                      open={isDueDateOpen}
                      label={`${translate('labels.dueDate')}*`}
                      value={
                        value
                          ? moment(value, DATE_FORMATS.displayedDateFormat)
                          : null
                      }
                      onClose={() => setDueDateOpen(false)}
                      onChange={(event) => {
                        onChange(
                          (event as Moment).format(
                            DATE_FORMATS.displayedDateFormat
                          )
                        );
                      }}
                      slotProps={{
                        textField: {
                          error: !!error,
                          helperText: !!error && translate('errors.dueDate'),
                          name: 'dueDate',
                          onClick: () => setDueDateOpen(true),
                          inputProps: { readOnly: true }
                        },
                        openPickerButton: {
                          onClick: () => setDueDateOpen(true)
                        }
                      }}
                    />
                  </FormControl>
                );
              }}
            />
          </Box>
        )}
        <Box
          sx={{
            ...verificationFormFieldsWrapper,
            height: '70px'
          }}
        >
          {currentInvoice.documentType !== DocumentType.NO_DOCUMENT && (
            <Controller
              name="expenseType"
              control={control}
              rules={{
                required: translate('errors.expenseType')
              }}
              render={({ field, fieldState: { error } }) => (
                <FormControl error={!!error} sx={verificationFormItem}>
                  <InputLabel>{`${translate(
                    'labels.expenseType'
                  )}*`}</InputLabel>
                  <Select
                    {...field}
                    value={field.value || ''}
                    sx={{ textAlign: 'left' }}
                    label={`${translate('labels.expenseType')}*`}
                    error={!!error}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 350
                        }
                      }
                    }}
                    renderValue={(selected) => {
                      const selectedOption = expenseTypeOptions.find(
                        (expenseType) => expenseType.id === selected
                      ) as InvoiceExpenseType;

                      return (
                        <span
                          style={{
                            color:
                              selectedOption && !selectedOption.isActive
                                ? 'gray'
                                : 'inherit'
                          }}
                        >
                          {!selectedOption?.isActive
                            ? `${selectedOption.name} ${translate(
                                'labels.deactivatedExpenseTypeValue'
                              )}`
                            : selectedOption.name}
                        </span>
                      );
                    }}
                  >
                    {expenseTypeOptions &&
                      expenseTypeOptions.map(
                        (expenseType: InvoiceExpenseType) =>
                          expenseType.isActive && (
                            <MenuItem
                              key={expenseType.id}
                              value={expenseType.id}
                              disabled={!expenseType.isActive}
                            >
                              {expenseType.name}
                            </MenuItem>
                          )
                      )}
                  </Select>
                  <FormHelperText>
                    {error && translate('errors.expenseType')}
                  </FormHelperText>
                </FormControl>
              )}
            />
          )}
          {currentInvoice.documentType === DocumentType.NO_DOCUMENT && (
            <Controller
              name="invoiceDate"
              control={control}
              rules={{ required: true }}
              render={({
                field: { onChange, value },
                fieldState: { error }
              }) => {
                return (
                  <FormControl error={!!error} sx={verificationFormItem}>
                    <DatePicker
                      sx={{ width: '100%' }}
                      format={DATE_FORMATS.displayedDateFormat}
                      open={isInvoiceDateOpen}
                      disableFuture
                      label={`${translate('labels.invoiceDate')}*`}
                      value={
                        value
                          ? moment(value, DATE_FORMATS.displayedDateFormat)
                          : null
                      }
                      onClose={() => setInvoiceDateOpen(false)}
                      onChange={(event) => {
                        onChange(
                          (event as Moment).format(
                            DATE_FORMATS.displayedDateFormat
                          )
                        );
                      }}
                      slotProps={{
                        textField: {
                          error: !!error,
                          helperText:
                            !!error && translate('errors.invoiceDate'),
                          name: 'invoiceDate',
                          onClick: () => setInvoiceDateOpen(true),
                          inputProps: { readOnly: true }
                        },
                        openPickerButton: {
                          onClick: () => setInvoiceDateOpen(true)
                        }
                      }}
                    />
                  </FormControl>
                );
              }}
            />
          )}
          {currentInvoice.documentType === DocumentType.NO_DOCUMENT && (
            <Controller
              name="invoiceAmount"
              control={control}
              rules={{
                required: true
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  onChange={(event) => {
                    field.onChange(
                      processMoneyInput(event.target.value, String(field.value))
                    );
                  }}
                  label={`${translate('labels.invoiceAmount')}*`}
                  name="invoiceAmount"
                  placeholder={translate('labels.invoiceAmount')}
                  sx={verificationFormItemNoHeight}
                />
              )}
            />
          )}
          {currentInvoice.documentType === DocumentType.NO_DOCUMENT && (
            <Controller
              name="currencyId"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { error } }) => (
                <FormControl error={!!error} sx={verificationFormItemNoHeight}>
                  <InputLabel>{`${translate('labels.currency')}*`}</InputLabel>
                  <Select
                    {...field}
                    sx={{ textAlign: 'left' }}
                    label={`${translate('labels.currency')}*`}
                    error={!!error}
                  >
                    {currencies &&
                      currencies.map((currency: CurrencyDropDown) => (
                        <MenuItem key={currency.id} value={currency.id}>
                          {currency.isoCode}
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText>
                    {error && translate('errors.currency')}
                  </FormHelperText>
                </FormControl>
              )}
            />
          )}
          {currentInvoice.stage !== InvoiceStages.DRAFT &&
            currentInvoice.documentType !== DocumentType.NO_DOCUMENT && (
              <Controller
                name="documentType"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth sx={verificationFormItem}>
                    <InputLabel>{`${translate(
                      'labels.documentType'
                    )}*`}</InputLabel>
                    <Select
                      {...field}
                      sx={{ textAlign: 'left' }}
                      label={translate('labels.documentType')}
                    >
                      {Object.values(DocumentType)
                        .filter(
                          (document) => document !== DocumentType.NO_DOCUMENT
                        )
                        .map((documentType) => (
                          <MenuItem key={documentType} value={documentType}>
                            {translate(
                              DOCUMENT_TYPES_TRANSLATION_KEYS[documentType]
                            )}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                )}
              />
            )}
        </Box>
        {currentInvoice.documentType !== DocumentType.NO_DOCUMENT && (
          <Box>
            <Box
              sx={{
                ...verificationFormFieldsWrapper,
                minHeight: '40px',
                flexDirection: { xs: 'column', sm: 'row' }
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' },
                  gap: { xs: 3, sm: 2 },
                  width: '100%'
                }}
              >
                <Controller
                  name="vatBase"
                  control={control}
                  rules={{ required: true }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      onChange={(event) => {
                        field.onChange(
                          processMoneyInput(
                            event.target.value,
                            String(field.value)
                          )
                        );
                      }}
                      error={!!error}
                      label={`${translate('labels.vatBase')}*`}
                      name="vatBase"
                      placeholder={translate('labels.vatBase')}
                      sx={verificationFormItemNoHeight}
                      helperText={error && translate('errors.vatBase')}
                    />
                  )}
                />
                <Controller
                  name="vatAmount"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      onChange={(event) => {
                        field.onChange(
                          processMoneyInput(
                            event.target.value,
                            String(field.value)
                          )
                        );
                      }}
                      label={`${translate('labels.vatAmount')}*`}
                      name="vatAmount"
                      placeholder={translate('labels.vatAmount')}
                      sx={verificationFormItemNoHeight}
                    />
                  )}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' },
                  gap: { xs: 3, sm: 2 },
                  width: '100%'
                }}
              >
                <Controller
                  name="invoiceAmount"
                  control={control}
                  rules={{
                    required: true,
                    validate: () => isInvoiceAmountValid
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      onChange={(event) => {
                        field.onChange(
                          processMoneyInput(
                            event.target.value,
                            String(field.value)
                          )
                        );
                      }}
                      label={`${translate('labels.invoiceAmount')}*`}
                      name="invoiceAmount"
                      error={!isInvoiceAmountValid}
                      placeholder={translate('labels.invoiceAmount')}
                      sx={verificationFormItemNoHeight}
                    />
                  )}
                />
                <Controller
                  name="currencyId"
                  control={control}
                  rules={{ required: true }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl
                      error={!!error}
                      sx={verificationFormItemNoHeight}
                    >
                      <InputLabel>{`${translate(
                        'labels.currency'
                      )}*`}</InputLabel>
                      <Select
                        {...field}
                        sx={{
                          textAlign: 'left'
                        }}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 350
                            }
                          }
                        }}
                        label={`${translate('labels.currency')}*`}
                        error={!!error}
                      >
                        {currencies &&
                          currencies.map((currency: CurrencyDropDown) => (
                            <MenuItem key={currency.id} value={currency.id}>
                              {currency.isoCode}
                            </MenuItem>
                          ))}
                      </Select>
                      <FormHelperText>
                        {error && translate('errors.currency')}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Box>
            </Box>
            <FormHelperText sx={{ height: 10 }} error={!isInvoiceAmountValid}>
              {!isInvoiceAmountValid && translate('errors.invoiceAmountEqual')}
            </FormHelperText>
          </Box>
        )}
        {currentInvoice.documentType === DocumentType.NO_DOCUMENT && (
          <Box
            sx={{
              ...verificationFormFieldsWrapper,
              height: '50px'
            }}
          >
            <Controller
              name="expenseType"
              control={control}
              rules={{
                required: translate('errors.expenseType')
              }}
              render={({ field, fieldState: { error } }) => (
                <FormControl error={!!error} sx={verificationFormItem}>
                  <InputLabel>{`${translate(
                    'labels.expenseType'
                  )}*`}</InputLabel>
                  <Select
                    {...field}
                    value={field.value || ''}
                    sx={{ textAlign: 'left' }}
                    label={`${translate('labels.expenseType')}*`}
                    error={!!error}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 350
                        }
                      }
                    }}
                    renderValue={(selected) => {
                      const selectedOption = expenseTypeOptions.find(
                        (expenseType) => expenseType.id === selected
                      ) as InvoiceExpenseType;

                      return (
                        <span
                          style={{
                            color:
                              selectedOption && !selectedOption.isActive
                                ? 'gray'
                                : 'inherit'
                          }}
                        >
                          {!selectedOption.isActive
                            ? `${selectedOption.name} ${translate(
                                'labels.deactivatedExpenseTypeValue'
                              )}`
                            : selectedOption.name}
                        </span>
                      );
                    }}
                  >
                    {expenseTypeOptions &&
                      expenseTypeOptions.map(
                        (expenseType: InvoiceExpenseType) =>
                          expenseType.isActive && (
                            <MenuItem
                              key={expenseType.id}
                              value={expenseType.id}
                              disabled={!expenseType.isActive}
                            >
                              {expenseType.name}
                            </MenuItem>
                          )
                      )}
                  </Select>
                  <FormHelperText>
                    {error && translate('errors.expenseType')}
                  </FormHelperText>
                </FormControl>
              )}
            />
          </Box>
        )}
        {currentInvoice.paymentStatus !== PaymentStatus.NOT_PAID &&
          currentInvoice.stage !== InvoiceStages.DRAFT && (
            <Box sx={{ display: 'flex' }}>
              <Controller
                name="paymentChannel"
                control={control}
                render={({ field }) => (
                  <FormControl {...field}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 1
                      }}
                    >
                      <Typography>
                        {`${translate('labels.paymentChannel')}:`}
                      </Typography>
                      <RadioGroup row {...field}>
                        {Object.values(PaymentChannel).map((channel) => (
                          <FormControlLabel
                            key={channel}
                            value={channel}
                            control={<Radio />}
                            label={translate(
                              PAYMENT_CHANNELS_TRANSLATION_KEYS[channel]
                            )}
                          />
                        ))}
                      </RadioGroup>
                    </Box>
                  </FormControl>
                )}
              />
            </Box>
          )}
        <Box
          sx={{
            ...verificationFormFieldsWrapper,
            minHeight: '50px',
            marginTop:
              currentInvoice.documentType === DocumentType.NO_DOCUMENT &&
              currentInvoice.paymentStatus === PaymentStatus.NOT_PAID
                ? 2
                : 0
          }}
        >
          <Controller
            name="documentDescription"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                multiline
                minRows={2}
                maxRows={2}
                label={translate('labels.comment')}
                name="documentDescription"
                placeholder={translate('labels.comment')}
                sx={verificationFormItemNoHeight}
              />
            )}
          />
        </Box>
        <Box
          sx={{
            ...verificationFormFieldsWrapper,
            minHeight: '50px',
            mt: 1
          }}
        >
          <Controller
            name="tags"
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                fullWidth
                freeSolo
                multiple
                disableCloseOnSelect
                options={availableTags}
                value={tags}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  if (params.inputValue !== '') {
                    const isExisting = options.some(
                      (option) =>
                        params.inputValue.toLowerCase() === option.toLowerCase()
                    );
                    if (!isExisting) {
                      filtered.push(`Add "${params.inputValue}"`);
                    }
                  }

                  return filtered;
                }}
                getOptionLabel={(option) => {
                  if (option.startsWith('Add "')) {
                    return option;
                  }
                  return option;
                }}
                onChange={(event, newValue) => {
                  const lastValue = newValue[newValue.length - 1];
                  if (lastValue && lastValue.startsWith('Add "')) {
                    const newTag = lastValue.slice(5, -1);
                    const updatedTags = [...tags, newTag];
                    setTags(updatedTags);
                    field.onChange(updatedTags);
                  } else {
                    const uniqueTags = Array.from(new Set(newValue));
                    setTags(uniqueTags);
                    field.onChange(uniqueTags);
                  }
                }}
                renderOption={(props, option) => (
                  <li {...props}>
                    {option.startsWith('Add "') ? <em>{option}</em> : option}
                  </li>
                )}
                renderTags={(value: string[], getTagProps) =>
                  value.map((option: string, index: number) => (
                    <Chip
                      sx={{ cursor: 'initial' }}
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                      key={option}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={translate('labels.tags')}
                    placeholder={translate('labels.addATag')}
                  />
                )}
              />
            )}
          />
        </Box>
      </LocalizationProvider>
    </Box>
  );
};
