import { useCallback } from 'react';

import { ChangeLogService } from 'openapi';

import { useInterceptor } from 'hooks/useInterceptor';

export const useChangeLogController = () => {
  const { interceptRequest } = useInterceptor();
  const { getChangeLogsByInvoiceId } = ChangeLogService;

  const getChangeLogs = useCallback(
    (invoiceId: number) =>
      interceptRequest(getChangeLogsByInvoiceId, {}, invoiceId),
    [getChangeLogsByInvoiceId]
  );

  return {
    getChangeLogs
  };
};
