import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

import { TrackingAction } from 'openapi';

import { COLUMNS_DEFAULT_OPTIONS } from 'utils/constants/constants';
import { TRACKING_ACTIONS_TRANSLATION_KEYS } from 'utils/constants/documentsSummary';
import { TranslateFunction } from 'utils/interfaces/Translations';

import { formatMoney } from './moneyHelper';

const getTimePeriodData = (
  rowValues: any[],
  period: string,
  showValue: boolean
) => {
  if (!rowValues || !Array.isArray(rowValues)) return '';
  const found = rowValues.find((item: any) => item.timePeriod === period);
  if (!found) {
    return '';
  }
  if (!found.count) {
    return '-';
  }
  return showValue ? `${formatMoney(found.value)} BGN` : found.count ?? '';
};

export const getDocumentInfoColumns = (
  translate: TranslateFunction,
  showValues: boolean
): GridColDef[] => {
  const columns: GridColDef[] = [
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'action',
      headerName: '',
      align: 'left',
      headerAlign: 'left',
      sortable: false,
      flex: 0.5,
      cellClassName: 'boldCell',
      valueGetter: (params: GridValueGetterParams) => {
        return translate(
          TRACKING_ACTIONS_TRANSLATION_KEYS[params.row.action as TrackingAction]
        );
      }
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'twentyFourHours',
      headerName: translate('labels.twentyFourHours'),
      align: 'left',
      headerAlign: 'left',
      sortable: false,
      flex: 0.5,
      valueGetter: (params: GridValueGetterParams) => {
        return getTimePeriodData(
          params.row.values,
          'TWENTY_FOUR_HOURS',
          showValues
        );
      }
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'sevenDays',
      headerName: translate('labels.sevenDays'),
      align: 'left',
      headerAlign: 'left',
      flex: 0.5,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) => {
        return getTimePeriodData(params.row.values, 'SEVEN_DAYS', showValues);
      }
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'fourteenDays',
      headerName: translate('labels.fourteenDays'),
      align: 'left',
      headerAlign: 'left',
      flex: 0.5,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) => {
        return getTimePeriodData(
          params.row.values,
          'FOURTEEN_DAYS',
          showValues
        );
      }
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'twentyEightDays',
      headerName: translate('labels.twentyEightDays'),
      align: 'left',
      headerAlign: 'left',
      flex: 0.5,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) => {
        return getTimePeriodData(
          params.row.values,
          'TWENTY_EIGHT_DAYS',
          showValues
        );
      }
    }
  ];

  return columns;
};
