import { useCallback } from 'react';

import { DashboardService } from 'openapi';

import { useInterceptor } from 'hooks/useInterceptor';

export const useDashboardController = () => {
  const { interceptRequest } = useInterceptor();

  const {
    getQuarterlyExpensesByYear,
    getInvoicesReceivedByYear,
    getBudgetByCompanyId,
    getExpensesByCompanyId,
    getCounterpartyExpenseByCompanyId,
    getBankAccountInformation,
    getDocumentSummary
  } = DashboardService;

  const getQuarterlyExpenses = useCallback(
    (year: number) => {
      return interceptRequest(getQuarterlyExpensesByYear, {}, year);
    },
    [getQuarterlyExpensesByYear]
  );

  const getInvoicesReceived = useCallback(
    (year: number) => {
      return interceptRequest(getInvoicesReceivedByYear, {}, year);
    },
    [getInvoicesReceivedByYear]
  );

  const getBudgetByCompany = useCallback(
    (companyId: number, year: number, month?: number) => {
      return interceptRequest(getBudgetByCompanyId, {}, companyId, year, month);
    },
    [getBudgetByCompanyId]
  );

  const getCompanyExpenses = useCallback((companyId: number, year: number) => {
    return interceptRequest(getExpensesByCompanyId, {}, companyId, year);
  }, []);

  const getCounterpartyExpenses = useCallback(
    (companyId: number, year: number) => {
      return interceptRequest(
        getCounterpartyExpenseByCompanyId,
        {},
        companyId,
        year
      );
    },
    []
  );

  const getBankAccountInfo = useCallback(
    (companyId: number, liveData: boolean) => {
      return interceptRequest(
        getBankAccountInformation,
        {},
        companyId,
        liveData
      );
    },
    []
  );

  const getDocumentSummaryInfo = useCallback(
    (companyId?: number) => {
      return interceptRequest(getDocumentSummary, {}, companyId);
    },
    [getDocumentSummary]
  );

  return {
    getQuarterlyExpenses,
    getInvoicesReceived,
    getBudgetByCompany,
    getCompanyExpenses,
    getCounterpartyExpenses,
    getBankAccountInfo,
    getDocumentSummaryInfo
  };
};
