import {
  Box,
  Grid,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  SxProps,
  Typography
} from '@mui/material';
import { BarChart, cheerfulFiestaPalette } from '@mui/x-charts';
import { useEffect, useState } from 'react';

import { useDashboardController } from 'api/controllers/DashboardController';

import { QuarterlyExpense } from 'openapi';

import { useTranslations } from 'context/TranslationContext';

import { useYearSelector } from 'hooks/useYearSelector';

import { formatMoney } from 'utils/helpers/moneyHelper';
import { mapHighlightToItems } from 'utils/mappers/charts';
import { chartTitle, yearSelector } from 'styles/components/Charts';

interface QuarterlyCompaniesExpensesProps {
  sx?: SxProps;
}

export const QuarterlyCompaniesExpenses = ({
  sx
}: QuarterlyCompaniesExpensesProps) => {
  const { getQuarterlyExpenses } = useDashboardController();
  const { translate } = useTranslations();
  const { selectedYear, setExistingYears, availableYears, setSelectedYear } =
    useYearSelector(false);

  const [series, setSeries] = useState<QuarterlyExpense[] | null>();

  const fetchExpenses = async () => {
    const response = await getQuarterlyExpenses(selectedYear);

    const seriesWithHighlight = mapHighlightToItems<QuarterlyExpense>(
      response.quarterlyExpenses
    );
    const seriesWithValueFormatter = seriesWithHighlight.map((item) => ({
      ...item,
      valueFormatter: (barItem: number) => formatMoney(barItem)
    }));

    setSeries(seriesWithValueFormatter);
    setExistingYears(response.years);
  };

  const handleYearChange = (event: SelectChangeEvent<number>) => {
    setSelectedYear(event.target.value as number);
  };

  const quarters = ['Q1', 'Q2', 'Q3', 'Q4'];

  useEffect(() => {
    setSeries(null);
    fetchExpenses();
  }, [selectedYear]);

  return (
    <Paper elevation={4} sx={sx}>
      <Stack height="100%">
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Stack justifyContent="center">
            <Typography sx={chartTitle} align="left">
              {translate('labels.quarterlyExpenses')}
            </Typography>
          </Stack>
          <Stack justifyContent="center" sx={{ mr: 2, mt: 2 }}>
            <Select
              value={selectedYear}
              size="small"
              sx={yearSelector}
              onChange={handleYearChange}
            >
              {availableYears.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Box>
        <Box height="90%">
          <BarChart
            colors={cheerfulFiestaPalette}
            loading={!series}
            slotProps={{
              legend: {
                itemGap: 10,
                markGap: 3,
                itemMarkWidth: 10,
                itemMarkHeight: 10,
                labelStyle: {
                  fontSize: 12
                }
              },
              loadingOverlay: {
                message: translate('labels.loading')
              },
              noDataOverlay: {
                message: translate('labels.noData')
              }
            }}
            margin={{ top: 80, left: 80 }}
            tooltip={{ trigger: 'item' }}
            series={series || []}
            xAxis={[{ scaleType: 'band', data: quarters }]}
          />
        </Box>
      </Stack>
    </Paper>
  );
};
