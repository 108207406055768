import { DialogTitle, Tooltip, CircularProgress, Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { grey } from '@mui/material/colors';

import { dialogTitle } from 'styles/components/ModalStyle';

interface PaymentMethodCardProps {
  readonly children: JSX.Element;
  readonly headerText: string;
  readonly tooltipText: string;
  readonly disabled?: boolean;
  readonly isLoading?: boolean;
}

export const PaymentMethodCard = ({
  children,
  headerText,
  tooltipText,
  isLoading,
  disabled = false
}: PaymentMethodCardProps) => {
  return (
    <Tooltip title={!isLoading && tooltipText}>
      <Card
        sx={{
          position: 'relative',
          height: '12rem',
          width: '25rem',
          bgcolor: disabled ? 'grey.500' : 'white',
          display: 'flex',
          flexDirection: 'column',
          boxShadow:
            '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)'
        }}
      >
        <DialogTitle sx={{ ...dialogTitle, textTransform: 'uppercase' }}>
          {headerText}
        </DialogTitle>
        <CardContent
          sx={{
            p: '1rem 1.5rem',
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          {children}
        </CardContent>

        {isLoading && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              bgcolor: 'rgba(255, 255, 255, 0.7)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 999
            }}
          >
            <CircularProgress sx={{ color: grey[600] }} />
          </Box>
        )}
      </Card>
    </Tooltip>
  );
};
