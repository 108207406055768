/* eslint-disable react/no-array-index-key */
import CloseIcon from '@mui/icons-material/Close';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import FilterListIcon from '@mui/icons-material/FilterList';
import HistoryIcon from '@mui/icons-material/History';
import {
  Paper,
  Box,
  Button,
  Stack,
  Skeleton,
  Tooltip,
  CircularProgress,
  Chip,
  Typography,
  MenuItem,
  Select,
  Backdrop,
  IconButton
} from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  GridValidRowModel,
  GridRowClassNameParams,
  GridCellParams,
  GridPaginationModel,
  GridSortModel,
  GridActionsCellItem
} from '@mui/x-data-grid';
import { useAtom } from 'jotai';
import {
  Dispatch,
  Key,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useDropzone } from 'react-dropzone';
import { useNavigate, useParams } from 'react-router-dom';

import { useExportController } from 'api/controllers/ExportController';
import { useInvoiceController } from 'api/controllers/InvoiceController';
import { useOpenBankingController } from 'api/controllers/OpenBankingController';

import {
  Company,
  CompanyIban,
  DocumentType,
  Invoice,
  InvoiceFilters,
  InvoiceQueryFilters,
  InvoiceRow,
  InvoiceSortColumns,
  InvoiceStages,
  OpenBankingPayment,
  OpenBankingPaymentResult,
  PaymentChannel,
  PaymentStatus
} from 'openapi';

import { ChangeLogList } from 'components/ChangeLog/ChangeLog';
import { SelectIbanForm } from 'components/forms/SelectIbanForm';
import { OverlayDropFiles } from 'components/overlays/OverlayDropFiles';
import { OverlayLoading } from 'components/overlays/OverlayLoading';
import { PaymentMethodCard } from 'components/shared/Card/PaymentMethodCard';
import { CustomGridFooter } from 'components/shared/CustomGridFooter/CustomGridFooter';
import { GridToolbar } from 'components/shared/GridToolbar/GridToolbar';
import { ConfirmationDialog } from 'components/shared/Modal/ConfirmationDialog';
import { Modal } from 'components/shared/Modal/Modal';
import { SeverityPill } from 'components/shared/SeverityPill/SeverityPill';
import { showErrorToastMessage } from 'components/shared/Toast/Toast';

import { usePermissions } from 'context/PermissionsContext';
import { useTranslations } from 'context/TranslationContext';

import { useDataGridHelpers } from 'hooks/useDataGridHelpers';
import { useModal } from 'hooks/useModal';

import {
  CHECK,
  COMPANY_PARAMETER,
  ID_PARAMETER,
  ACTIONS,
  APPROVERS,
  DEFAULT_GRID_ROW_HEIGHT,
  ACCOUNTED_INVOICE_STYLE,
  PAYMENT_STATUS
} from 'utils/constants/constants';
import { INVOICES_FILTERS_EXPANDED_HEIGHT } from 'utils/constants/filters';
import {
  PAYMENT_CHANNELS_TRANSLATION_KEYS,
  PAYMENT_STATUS_STYLES,
  PAYMENT_STATUSES_TRANSLATION_KEYS
} from 'utils/constants/invoices';
import { FilterType } from 'utils/enums/FilterType';
import { downloadZippedInvoices } from 'utils/helpers/downloadZippedInvoices';
import { exportTxtFile } from 'utils/helpers/exportTxtFile';
import {
  getInvoiceColumns,
  getInvoiceColumnsWithSkeleton,
  getContainerHeight,
  getCounterpartyNameRow
} from 'utils/helpers/invoiceHelpers';
import { formatMoney } from 'utils/helpers/moneyHelper';
import {
  getUnsupportedFormatMessage,
  showWarnMessage
} from 'utils/helpers/toastHelpers';
import { ExportForPaymentForm } from 'utils/interfaces/ExportForPaymentForm';
import { InvoicesFiltersForm } from 'utils/interfaces/InvoiceProps';
import { AppRoutesEnum } from 'utils/routes';

import { commonDataGridContainerStyle } from 'styles/components/DataGridStyle';
import { invoicesDataGrid } from 'styles/components/InvoicesDataGridStyle';

import {
  areFiltrsVisibleAtom,
  exportButtonTooltipTextAtom,
  filterTypeAtom,
  isExportButtonDisabledAtom,
  selectedCompanyIdAtom,
  userAtom
} from 'state/state';

import { InvoicesFilterPanel } from './InvoicesFilterPanel';

interface InvoicesDataGridProp {
  readonly invoicesList: Invoice[];
  readonly filters: InvoiceQueryFilters;
  readonly stateRoute: string;
  readonly handleUploadInvoice?: (
    fileData: FileList | File[],
    field: string
  ) => Promise<void>;
  readonly setInvoicesList: Dispatch<SetStateAction<Invoice[]>>;
  readonly invoicesFiltersData?: InvoiceFilters;
  readonly handleApplyFilters: (data: InvoicesFiltersForm | null) => void;
  readonly handlePaginationModelChange: (
    newPaginationModel: GridPaginationModel
  ) => void;
  readonly paginationModel: GridPaginationModel;
  readonly rowCount: number;
  readonly handleSortModelChange: (sortModel: GridSortModel) => void;
  readonly fetchInvoices: () => void;
  readonly isLoading: boolean;
  readonly companies: Company[];
  readonly companyIbans?: CompanyIban[];
}

export const InvoicesDataGrid = ({
  invoicesList,
  filters,
  stateRoute,
  handleUploadInvoice,
  setInvoicesList,
  invoicesFiltersData,
  handleApplyFilters,
  handlePaginationModelChange,
  rowCount,
  paginationModel,
  handleSortModelChange,
  fetchInvoices,
  isLoading,
  companies,
  companyIbans
}: InvoicesDataGridProp) => {
  const navigate = useNavigate();
  const { companyId } = useParams();

  const [filterType] = useAtom(filterTypeAtom);
  const isWithoutQuickFilter = useMemo(
    () => filterType === FilterType.Initial,
    [filterType]
  );

  const { permissions } = usePermissions();
  const { translate } = useTranslations();

  const [user] = useAtom(userAtom);
  const [selectedCompanyId] = useAtom(selectedCompanyIdAtom);

  const {
    isOpen: isDeleteModalOpen,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal
  } = useModal();
  const {
    isOpen: isAccountModalOpen,
    openModal: openAccountModal,
    closeModal: closeAccountModal
  } = useModal();
  const {
    isOpen: isPayModalOpen,
    openModal: openPayModal,
    closeModal: closePayModal
  } = useModal();
  const {
    isOpen: isApproveModalOpen,
    openModal: openApproveModal,
    closeModal: closeApproveModal
  } = useModal();
  const {
    isOpen: isRejectModalOpen,
    openModal: openRejectModal,
    closeModal: closeRejectModal
  } = useModal();
  const {
    isOpen: isChangeLogModalOpen,
    openModal: openChangeLogModal,
    closeModal: closeChangeLogModal
  } = useModal();

  const { getSeverityColor } = useDataGridHelpers();

  const {
    deleteInvoices,
    setIsAccounted,
    markAsPaidByIds,
    approveInvoicesByIds,
    rejectInvoicesByIds,
    createWithoutDocument
  } = useInvoiceController();
  const {
    exportInvoicesPayment,
    exportInvoicesZipByIds,
    exportInvoicesExcelByFilters,
    exportInvoicesExcelByCompanyIdAndFilters
  } = useExportController();
  const { initiatePaymentByCompanyId } = useOpenBankingController();

  localStorage.setItem('state', stateRoute);
  const isOnAllCompanies = stateRoute === AppRoutesEnum.INVOICES;

  const [areFiltersVisible, setAreFiltersVisible] =
    useAtom(areFiltrsVisibleAtom);
  const [isExportButtonDisabled] = useAtom(isExportButtonDisabledAtom);
  const [exportTooltip, setExportButtonTooltipText] = useAtom(
    exportButtonTooltipTextAtom
  );

  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [selectedSingleInvoiceId, setSelectedSingleInvoiceId] =
    useState<number>();
  const [isProcessingDownload, setIsProcessingDownload] =
    useState<boolean>(false);
  const [selectedPaymentChannel, setSelectedPaymentChannel] =
    useState<PaymentChannel>(PaymentChannel.BANK);
  const [filteredEntries, setFilteredEntries] = useState<Invoice[]>([]);
  const [isPaymentLoading, setIsPaymentLoading] = useState<boolean>(false);

  const handleCloseModals = () => {
    closeDeleteModal();
    closeAccountModal();
    closePayModal();
    closeApproveModal();
    closeRejectModal();
    closeChangeLogModal();
    setSelectedSingleInvoiceId(undefined);
    setSelectedRows([]);
  };

  const confirmDelete = async () => {
    handleCloseModals();
    await deleteInvoices({
      invoiceIds: selectedSingleInvoiceId
        ? [selectedSingleInvoiceId]
        : selectedRows
    });

    const updatedInvoiceList = invoicesList.filter(
      (invoice) =>
        !selectedRows.includes(invoice.id as number) ||
        invoice.id !== selectedSingleInvoiceId
    );
    setInvoicesList(updatedInvoiceList);
  };

  const confirmAccount = async () => {
    handleCloseModals();
    await setIsAccounted(
      selectedSingleInvoiceId ? [selectedSingleInvoiceId] : selectedRows
    );

    const updatedInvoiceList = invoicesList.map((invoice) => {
      if (
        selectedRows.includes(invoice.id as number) ||
        invoice.id === selectedSingleInvoiceId
      ) {
        return {
          ...invoice,
          isAccounted: true
        };
      }
      return invoice;
    });
    setInvoicesList(updatedInvoiceList);
    fetchInvoices();
  };

  const confirmMarkAsPaid = async () => {
    handleCloseModals();
    await markAsPaidByIds(
      selectedPaymentChannel,
      selectedSingleInvoiceId ? [selectedSingleInvoiceId] : selectedRows
    );

    const updatedInvoiceList = invoicesList.map((invoice) => {
      if (
        selectedRows.includes(invoice.id as number) ||
        invoice.id === selectedSingleInvoiceId
      ) {
        return {
          ...invoice,
          stage: InvoiceStages.FINALIZED
        };
      }
      return invoice;
    });
    setInvoicesList(updatedInvoiceList);
    fetchInvoices();
  };

  const confirmApprove = async () => {
    handleCloseModals();
    await approveInvoicesByIds(
      selectedSingleInvoiceId ? [selectedSingleInvoiceId] : selectedRows
    );

    const updatedInvoiceList = invoicesList.map((invoice) => {
      if (
        selectedRows.includes(invoice.id as number) ||
        invoice.id === selectedSingleInvoiceId
      ) {
        return {
          ...invoice,
          stage: InvoiceStages.APPROVED
        };
      }
      return invoice;
    });
    setInvoicesList(updatedInvoiceList);
    fetchInvoices();
  };

  const confirmReject = async () => {
    handleCloseModals();
    await rejectInvoicesByIds(
      selectedSingleInvoiceId ? [selectedSingleInvoiceId] : selectedRows
    );

    const updatedInvoiceList = invoicesList.map((invoice) => {
      if (
        selectedRows.includes(invoice.id as number) ||
        invoice.id === selectedSingleInvoiceId
      ) {
        return {
          ...invoice,
          stage: InvoiceStages.REJECTED
        };
      }
      return invoice;
    });
    setInvoicesList(updatedInvoiceList);
    fetchInvoices();
  };

  const confirmExportForPayment = async (values: ExportForPaymentForm) => {
    handleCloseModals();
    const body = {
      ibanId: values.ibanId,
      invoiceIds: selectedRows
    };
    const { fileContent, fileName } = await exportInvoicesPayment(
      Number(companyId),
      body
    );
    await exportTxtFile(fileContent, fileName);
    fetchInvoices();
  };

  const confirmDownloadInvoices = async () => {
    handleCloseModals();
    setIsProcessingDownload(true);
    let response;
    try {
      response = await exportInvoicesZipByIds(selectedRows);
    } finally {
      setIsProcessingDownload(false);
    }
    const { fileContent, fileName } = await response;
    await downloadZippedInvoices(fileContent, fileName);
    fetchInvoices();
  };

  const handleCreateClick = async () => {
    const createdInvoice = await createWithoutDocument(
      Number(isOnAllCompanies ? selectedCompanyId : companyId)
    );
    navigate(
      AppRoutesEnum.INVOICE_VERIFICATION.replace(
        ID_PARAMETER,
        createdInvoice.id.toString()
      ).replace(
        COMPANY_PARAMETER,
        String(isOnAllCompanies ? selectedCompanyId : companyId)
      ),
      { state: { invoice: stateRoute } }
    );
  };

  const handleExportToExcel = useCallback(async () => {
    if (isOnAllCompanies) {
      const response = await exportInvoicesExcelByFilters(filters);
      const { fileContent, fileName } = await response;
      await exportTxtFile(fileContent, fileName);
      return;
    }

    const response = await exportInvoicesExcelByCompanyIdAndFilters(
      Number(companyId),
      filters
    );
    const { fileContent, fileName } = await response;
    await exportTxtFile(fileContent, fileName);
  }, [filters, companyId, exportInvoicesExcelByFilters, exportTxtFile]);

  const getDisabledDeleteTooltip = useCallback(
    (row: GridValidRowModel) => {
      if (
        row.stage === InvoiceStages.UPLOADED ||
        row.stage === InvoiceStages.DRAFT
      ) {
        return translate('messages.disabledDeleteInvoicePermission');
      }
      return translate('messages.disabledDeleteInvoice');
    },
    [translate]
  );

  const onDrop = useCallback(
    (acceptedFiles: any, fileRejections: any) => {
      if (fileRejections.length > 0) {
        const { label, message } = getUnsupportedFormatMessage(fileRejections);
        const notSupportedMessage = translate(label, {
          invoices: message
        });

        showWarnMessage(notSupportedMessage);
      }

      if (handleUploadInvoice && acceptedFiles.length > 0) {
        handleUploadInvoice(acceptedFiles, 'invoiceFile');
      }
    },
    [handleUploadInvoice]
  );

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      onDrop,
      noClick: true,
      multiple: true,
      accept: {
        'application/pdf': ['.pdf'],
        'image/png': ['.png'],
        'image/jpeg': ['.jpg', '.jpeg'],
        'image/heic': ['.heic']
      }
    });
  const canBeDroppable = useMemo(
    () => filterType === FilterType.Initial,
    [filterType, isOnAllCompanies]
  );

  const columns: GridColDef[] = useMemo(() => {
    return getInvoiceColumns(isOnAllCompanies, translate).map((column) => {
      switch (column.field) {
        case InvoiceSortColumns.STAGE:
          return {
            ...column,
            renderCell: (params: any) => {
              const { color, backgroundColor } = getSeverityColor(
                params.row[InvoiceSortColumns.STAGE]
              );
              return (
                <Box sx={{ width: '100%' }}>
                  {params.row[InvoiceSortColumns.STAGE] ===
                  InvoiceStages.UPLOADED ? (
                    <Skeleton animation="wave" />
                  ) : (
                    <SeverityPill
                      color={color}
                      backgroundColor={backgroundColor}
                    >
                      {params.row[InvoiceSortColumns.STAGE]}
                    </SeverityPill>
                  )}
                </Box>
              );
            }
          };
        case InvoiceSortColumns.INVOICE_AMOUNT:
          return {
            ...column,
            renderCell: ({ row }: GridCellParams) =>
              row[InvoiceSortColumns.STAGE] === InvoiceStages.UPLOADED ? (
                <Box sx={{ width: '100%' }}>
                  <Skeleton animation="wave" />
                </Box>
              ) : (
                `${
                  row.documentType === DocumentType.CREDIT_NOTE ? '-' : ''
                }${formatMoney(row[InvoiceSortColumns.INVOICE_AMOUNT])} ${
                  row[InvoiceSortColumns.CURRENCY] || ''
                }`
              ),
            valueGetter: (params: GridValueGetterParams) =>
              `${formatMoney(params.row[InvoiceSortColumns.INVOICE_AMOUNT])} ${
                params.row[InvoiceSortColumns.CURRENCY] || ''
              }`
          };
        case InvoiceSortColumns.PAYMENT_STATUS:
          return {
            ...column,
            renderCell: ({ row }: GridCellParams) => {
              const { icon, backgroundColor, textColor } =
                PAYMENT_STATUS_STYLES[row[PAYMENT_STATUS] as PaymentStatus];
              const label = translate(
                PAYMENT_STATUSES_TRANSLATION_KEYS[
                  row[PAYMENT_STATUS] as PaymentStatus
                ]
              );
              const paymentChannel =
                PAYMENT_CHANNELS_TRANSLATION_KEYS[
                  row.paymentChannel as PaymentChannel
                ] &&
                translate(
                  PAYMENT_CHANNELS_TRANSLATION_KEYS[
                    row.paymentChannel as PaymentChannel
                  ]
                );
              return row[InvoiceSortColumns.STAGE] ===
                InvoiceStages.UPLOADED ? (
                <Box sx={{ width: '100%' }}>
                  <Skeleton animation="wave" />
                </Box>
              ) : (
                <Tooltip title={paymentChannel}>
                  <Chip
                    label={label}
                    icon={icon}
                    size="small"
                    sx={{
                      backgroundColor,
                      color: textColor,
                      '& .MuiChip-icon': {
                        color: textColor
                      }
                    }}
                  />
                </Tooltip>
              );
            }
          };
        case APPROVERS:
          return {
            ...column,
            renderCell: ({ row }: GridCellParams) =>
              row[InvoiceSortColumns.STAGE] === InvoiceStages.UPLOADED ? (
                <Box sx={{ width: '100%' }}>
                  <Skeleton animation="wave" />
                </Box>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    gap: '0.2rem'
                  }}
                >
                  {row.expenseTypeApprovers?.length ? (
                    <Tooltip
                      title={
                        <>
                          <Box
                            component="div"
                            sx={{
                              display: 'inline',
                              fontWeight: 600
                            }}
                          >
                            {`${translate('labels.expenseTypeApprovers')}:`}
                          </Box>
                          {row.expenseTypeApprovers.map(
                            (
                              approver: string,
                              index: Key | null | undefined
                            ) => (
                              <span key={index}>
                                <br />
                                {approver}
                              </span>
                            )
                          )}
                        </>
                      }
                    >
                      <Chip className="approvers-chip" label="ET" />
                    </Tooltip>
                  ) : null}
                  {row.counterpartyApprovers?.length ? (
                    <Tooltip
                      title={
                        <>
                          <Box
                            component="div"
                            sx={{ display: 'inline', fontWeight: 600 }}
                          >
                            {`${translate('labels.counterpartyApprovers')}:`}
                          </Box>
                          {row.counterpartyApprovers.map(
                            (
                              approver: string,
                              index: Key | null | undefined
                            ) => (
                              <span key={index}>
                                <br />
                                {approver}
                              </span>
                            )
                          )}
                        </>
                      }
                    >
                      <Chip className="approvers-chip" label="CP" />
                    </Tooltip>
                  ) : null}
                  {row.amountApprovers?.length ? (
                    <Tooltip
                      title={
                        <>
                          <Box
                            component="div"
                            sx={{ display: 'inline', fontWeight: 600 }}
                          >
                            {`${translate('labels.amountApprovers')}:`}
                          </Box>
                          {row.amountApprovers.map(
                            (
                              approver: string,
                              index: Key | null | undefined
                            ) => (
                              <span key={index}>
                                <br />
                                {approver}
                              </span>
                            )
                          )}
                        </>
                      }
                    >
                      <Chip className="approvers-chip" label="A" />
                    </Tooltip>
                  ) : null}
                </Box>
              )
          };
        case InvoiceSortColumns.COUNTERPARTY_NAME:
          return {
            ...column,
            renderCell: ({ row }: GridCellParams) =>
              row[InvoiceSortColumns.STAGE] === InvoiceStages.UPLOADED ? (
                <Box sx={{ width: '100%' }}>
                  <Skeleton animation="wave" />
                </Box>
              ) : (
                getCounterpartyNameRow(row, column, translate)
              )
          };
        case InvoiceSortColumns.INVOICE_NUMBER:
          return {
            ...column,
            renderCell: ({ row }: GridCellParams) =>
              getInvoiceColumnsWithSkeleton(
                row,
                InvoiceSortColumns.INVOICE_NUMBER
              )
          };
        case InvoiceSortColumns.INVOICE_DATE:
          return {
            ...column,
            renderCell: ({ row }: GridCellParams) =>
              getInvoiceColumnsWithSkeleton(
                row,
                InvoiceSortColumns.INVOICE_DATE
              )
          };
        case InvoiceSortColumns.INSERTED_BY:
          return {
            ...column,
            renderCell: ({ row }: GridCellParams) =>
              getInvoiceColumnsWithSkeleton(row, InvoiceSortColumns.INSERTED_BY)
          };
        case InvoiceSortColumns.EXPENSE_TYPE:
          return {
            ...column,
            renderCell: ({ row }: GridCellParams) =>
              getInvoiceColumnsWithSkeleton(
                row,
                InvoiceSortColumns.EXPENSE_TYPE
              )
          };
        case InvoiceSortColumns.INSERTED_AT:
          return {
            ...column,
            renderCell: ({ row }: GridCellParams) =>
              getInvoiceColumnsWithSkeleton(row, InvoiceSortColumns.INSERTED_AT)
          };
        case ACTIONS:
          return {
            ...column,
            minWidth: filterType === FilterType.Initial ? 100 : 50,
            maxWidth: filterType === FilterType.Initial ? 100 : 50,
            renderCell: ({ row }: any) => (
              <Box display="flex" justifyContent="center">
                {filterType === FilterType.Initial && (
                  <Tooltip
                    placement="left"
                    title={
                      !row.isDeletable ? getDisabledDeleteTooltip(row) : ''
                    }
                  >
                    <span>
                      <GridActionsCellItem
                        disabled={!row.isDeletable}
                        icon={
                          <DeleteTwoToneIcon
                            sx={(theme) => ({
                              color: row.isDeletable
                                ? theme.palette.error.main
                                : 'default',
                              fontSize: '1.5rem'
                            })}
                            titleAccess={translate('labels.delete')}
                          />
                        }
                        label={translate('labels.delete')}
                        onClick={() => {
                          openDeleteModal();
                          setSelectedSingleInvoiceId(row.id);
                        }}
                      />
                    </span>
                  </Tooltip>
                )}
                <GridActionsCellItem
                  icon={
                    <HistoryIcon
                      color="primary"
                      sx={{
                        fontSize: '1.5rem'
                      }}
                      titleAccess={translate('labels.changeLog')}
                    />
                  }
                  label={translate('labels.changeLog')}
                  onClick={() => {
                    setSelectedSingleInvoiceId(row.id);
                    openChangeLogModal();
                  }}
                />
              </Box>
            )
          };
        case InvoiceSortColumns.SHORT_COMPANY_NAME:
          return {
            ...column,
            renderCell: ({ row }: GridCellParams) =>
              getInvoiceColumnsWithSkeleton(
                row,
                InvoiceSortColumns.SHORT_COMPANY_NAME
              )
          };
        default:
          return column;
      }
    });
  }, [translate, isOnAllCompanies, filterType, user, invoicesList]);

  const getRowId = useCallback(
    (row: GridValidRowModel) => row.id || row.name,
    []
  );

  const getRowClassName = useCallback(
    (params: GridRowClassNameParams<GridValidRowModel>) => {
      if (params.row.isAccounted) {
        return ACCOUNTED_INVOICE_STYLE;
      }
      return '';
    },
    []
  );

  const onCellClick = useCallback(
    (params: GridCellParams) => {
      if (
        params.field !== ACTIONS &&
        params.field !== CHECK &&
        params.row[InvoiceSortColumns.STAGE] !== InvoiceStages.UPLOADED
      ) {
        navigate(
          AppRoutesEnum.INVOICE_VERIFICATION.replace(
            ID_PARAMETER,
            params.row.id.toString()
          ).replace(COMPANY_PARAMETER, params.row.companyId.toString()),
          { state: { invoice: stateRoute } }
        );
      }
    },
    [navigate, stateRoute]
  );

  const handleOpenBankingPayment = async (
    ibanId: number,
    documentIds: number[]
  ) => {
    handleCloseModals();
    setIsPaymentLoading(true);
    const requestBody: OpenBankingPayment = {
      documentIds
    };
    let result: OpenBankingPaymentResult = {};
    try {
      result = await initiatePaymentByCompanyId(
        Number(companyId),
        ibanId,
        requestBody
      );
    } finally {
      setIsPaymentLoading(false);
    }
    fetchInvoices();
    if (
      !result.url ||
      result.paymentStatus === PaymentStatus.OPEN_BANKING_FAILED
    ) {
      showErrorToastMessage(result.reasonForFail);
      return;
    }
    window.open(result.url, '_blank');
  };

  useEffect(() => {
    setFilteredEntries(
      invoicesList.filter((entry: any) => selectedRows?.includes(entry.id))
    );
  }, [selectedRows, invoicesList]);

  const openBankingIbanOptions = useMemo(
    () => companyIbans?.filter((iban) => iban.isOpenBankingConsented),
    [companyIbans]
  );

  const exportPaymentIbanOptions = useMemo(
    () => companyIbans?.filter((iban) => iban.isExportable),
    [companyIbans]
  );

  const handleShowFilters = () => {
    setAreFiltersVisible((previousValue) => !previousValue);
  };

  const height = useMemo(
    () =>
      getContainerHeight(
        areFiltersVisible,
        INVOICES_FILTERS_EXPANDED_HEIGHT,
        isWithoutQuickFilter
      ),
    [areFiltersVisible, isWithoutQuickFilter]
  );

  const shouldShowMultiSelectInfo = useMemo(() => {
    return selectedRows.length > 1 && selectedSingleInvoiceId === undefined;
  }, [selectedRows, selectedSingleInvoiceId]);

  const shouldDisableOpenBanking = useMemo(() => {
    return (
      !openBankingIbanOptions?.length ||
      !(filteredEntries as InvoiceRow[])?.every((entry) => entry.isPayable) ||
      !filteredEntries.every(
        (entry) => entry.currency === filteredEntries[0].currency
      )
    );
  }, [openBankingIbanOptions, selectedRows, filteredEntries]);

  const openBankingTooltipText = useMemo(() => {
    if (!openBankingIbanOptions?.length) {
      return translate('messages.noAvailableIbans');
    }

    if (!(filteredEntries as InvoiceRow[])?.every((entry) => entry.isPayable)) {
      return translate('messages.disabledExportNotPayable');
    }

    if (
      !filteredEntries.every(
        (entry) => entry.currency === filteredEntries[0].currency
      )
    ) {
      return translate('messages.notAllInvoicesSameCurrency');
    }

    return '';
  }, [openBankingIbanOptions, filteredEntries]);

  useEffect(() => {
    if (!exportPaymentIbanOptions?.length) {
      setExportButtonTooltipText(translate('messages.noExportableIbans'));
    }
  }, [exportPaymentIbanOptions, setExportButtonTooltipText, translate]);

  return (
    <Stack sx={commonDataGridContainerStyle}>
      {isWithoutQuickFilter && (
        <Box display="flex" justifyContent="flex-end" gap={2}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleExportToExcel}
          >
            {translate('buttons.exportToExcel')}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleShowFilters}
          >
            <FilterListIcon />
            {translate('buttons.filters')}
          </Button>
        </Box>
      )}
      {(isProcessingDownload || isPaymentLoading) && <OverlayLoading />}
      <Stack
        sx={{
          ...commonDataGridContainerStyle,
          height
        }}
        {...(canBeDroppable ? getRootProps() : {})}
      >
        {canBeDroppable && (
          <input {...getInputProps()} style={{ display: 'none' }} />
        )}
        {isWithoutQuickFilter && (
          <InvoicesFilterPanel
            handleApplyFilters={handleApplyFilters}
            filtersData={invoicesFiltersData}
            areFiltersVisible={areFiltersVisible}
            isOnAllCompanies={isOnAllCompanies}
          />
        )}
        <Paper
          elevation={4}
          sx={{
            ...commonDataGridContainerStyle,
            marginTop: '1rem',
            minHeight: 'calc(100% - 202px)',
            outline: isDragActive ? '2px dashed #2196f3' : 'none',
            position: 'relative'
          }}
        >
          <DataGrid
            rows={invoicesList}
            columns={columns}
            getRowId={getRowId}
            loading={isLoading}
            sx={invoicesDataGrid}
            getRowClassName={getRowClassName}
            sortingMode="server"
            paginationMode="server"
            rowCount={rowCount}
            onPaginationModelChange={handlePaginationModelChange}
            paginationModel={paginationModel}
            pageSizeOptions={[]}
            slots={{
              footer: CustomGridFooter,
              toolbar: GridToolbar,
              noResultsOverlay: CircularProgress
            }}
            onSortModelChange={handleSortModelChange}
            rowSelectionModel={selectedRows}
            onRowSelectionModelChange={(rows) =>
              setSelectedRows(rows as number[])
            }
            rowHeight={DEFAULT_GRID_ROW_HEIGHT}
            onCellClick={onCellClick}
            checkboxSelection={permissions.INVOICES.read}
            disableRowSelectionOnClick
            disableColumnMenu
            slotProps={{
              toolbar: {
                selectedRows,
                companyIbans,
                isDefaultToolbarHidden: true,
                handleDeleteClick:
                  filterType === FilterType.Initial
                    ? openDeleteModal
                    : undefined,
                handleUploadInvoice:
                  filterType === FilterType.Initial
                    ? handleUploadInvoice
                    : undefined,
                handleAccountClick:
                  permissions.INVOICE_ACCOUNTING.update &&
                  (filterType === FilterType.Accounting ||
                    filterType === FilterType.Initial)
                    ? openAccountModal
                    : undefined,
                handlePayClick:
                  permissions.INVOICE_PAYMENTS.create &&
                  (filterType === FilterType.Payment ||
                    filterType === FilterType.Initial)
                    ? openPayModal
                    : undefined,
                handleApproveClick:
                  permissions.INVOICE_APPROVAL.update &&
                  (filterType === FilterType.Approval ||
                    filterType === FilterType.Initial)
                    ? openApproveModal
                    : undefined,
                handleRejectClick:
                  permissions.INVOICE_APPROVAL.update &&
                  (filterType === FilterType.Approval ||
                    filterType === FilterType.Initial)
                    ? openRejectModal
                    : undefined,
                handleDownloadClick: confirmDownloadInvoices,
                handleCreateClick:
                  filterType === FilterType.Initial
                    ? handleCreateClick
                    : undefined,
                entries: invoicesList,
                deleteButtonTooltip: translate(
                  'messages.disabledDeleteInvoices'
                ),
                companies,
                isOnAllCompanies
              }
            }}
            localeText={{
              noRowsLabel: translate('labels.noData')
            }}
          />
          {canBeDroppable && isDragActive && (
            <OverlayDropFiles isReject={isDragReject} />
          )}
          <ConfirmationDialog
            title={translate('titles.delete')}
            isOpen={isDeleteModalOpen}
            onClose={handleCloseModals}
            size="sm"
            onConfirm={confirmDelete}
          >
            {shouldShowMultiSelectInfo
              ? translate('messages.deleteInvoicesConfirmation', {
                  numberOfInvoices: selectedRows.length.toString()
                })
              : translate('messages.deleteInvoiceConfirmation')}
          </ConfirmationDialog>
          <ConfirmationDialog
            title={translate('titles.book')}
            isOpen={isAccountModalOpen}
            onClose={handleCloseModals}
            size="sm"
            onConfirm={confirmAccount}
          >
            {shouldShowMultiSelectInfo
              ? translate('messages.accountInvoicesConfirmation', {
                  numberOfInvoices: selectedRows.length.toString()
                })
              : translate('messages.accountInvoiceConfirmation')}
          </ConfirmationDialog>
          <Backdrop
            sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer })}
            open={isPayModalOpen}
          >
            <IconButton
              aria-label="close"
              onClick={handleCloseModals}
              sx={{
                position: 'relative',
                left: !isOnAllCompanies ? 1290 : 450,
                bottom: 120,
                color: '#fff'
              }}
            >
              <CloseIcon />
            </IconButton>
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                justifyContent: 'center',
                alignItems: 'center',
                p: 2
              }}
            >
              <PaymentMethodCard
                tooltipText=""
                headerText={translate('buttons.markAsPaid')}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: '100%'
                  }}
                >
                  <Box display="flex">
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: '0.8rem',
                        mb: 1,
                        textAlign: 'start'
                      }}
                    >
                      {translate('messages.markAsPaid')}
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Select
                      size="small"
                      sx={{ textAlign: 'left' }}
                      fullWidth
                      value={selectedPaymentChannel}
                      onChange={(event) =>
                        setSelectedPaymentChannel(
                          event.target.value as PaymentChannel
                        )
                      }
                    >
                      {Object.values(PaymentChannel).map((channel) => (
                        <MenuItem key={channel} value={channel}>
                          {translate(
                            PAYMENT_CHANNELS_TRANSLATION_KEYS[channel]
                          )}
                        </MenuItem>
                      ))}
                    </Select>
                    <Button onClick={confirmMarkAsPaid} variant="contained">
                      {translate('buttons.mark')}
                    </Button>
                  </Box>
                </Box>
              </PaymentMethodCard>
              {!isOnAllCompanies && (
                <>
                  <PaymentMethodCard
                    headerText={translate('buttons.exportForPayment')}
                    disabled={
                      isExportButtonDisabled ||
                      !exportPaymentIbanOptions?.length
                    }
                    tooltipText={exportTooltip}
                    isLoading={!companyIbans}
                  >
                    <SelectIbanForm
                      onSubmit={confirmExportForPayment}
                      ibanOptions={exportPaymentIbanOptions || []}
                      disabled={
                        isExportButtonDisabled ||
                        !exportPaymentIbanOptions?.length
                      }
                      description={translate('messages.exportForPayment')}
                    />
                  </PaymentMethodCard>
                  <PaymentMethodCard
                    tooltipText={openBankingTooltipText}
                    headerText={translate('labels.openBanking')}
                    disabled={shouldDisableOpenBanking}
                    isLoading={!companyIbans}
                  >
                    <SelectIbanForm
                      onSubmit={({ ibanId }) =>
                        handleOpenBankingPayment(
                          ibanId,
                          selectedRows.length
                            ? selectedRows
                            : [selectedSingleInvoiceId!]
                        )
                      }
                      ibanOptions={openBankingIbanOptions || []}
                      isForOpenBanking
                      description={translate('messages.openBanking')}
                      disabled={shouldDisableOpenBanking}
                    />
                  </PaymentMethodCard>
                </>
              )}
            </Box>
          </Backdrop>
          <ConfirmationDialog
            title={translate('buttons.approve')}
            isOpen={isApproveModalOpen}
            onClose={handleCloseModals}
            size="sm"
            onConfirm={confirmApprove}
          >
            {shouldShowMultiSelectInfo
              ? translate('messages.approveInvoicesConfirmation', {
                  numberOfInvoices: selectedRows.length.toString()
                })
              : translate('messages.approveInvoiceConfirmation')}
          </ConfirmationDialog>
          <ConfirmationDialog
            title={translate('buttons.reject')}
            isOpen={isRejectModalOpen}
            onClose={handleCloseModals}
            size="sm"
            onConfirm={confirmReject}
          >
            {shouldShowMultiSelectInfo
              ? translate('messages.rejectInvoicesConfirmation', {
                  numberOfInvoices: selectedRows.length.toString()
                })
              : translate('messages.rejectInvoiceConfirmation')}
          </ConfirmationDialog>
          <Modal
            headerTitle={translate('titles.changeLog')}
            isOpen={isChangeLogModalOpen}
            hide={handleCloseModals}
            maxWidth="xl"
            noPaddingY
          >
            <ChangeLogList invoiceId={Number(selectedSingleInvoiceId)} />
          </Modal>
        </Paper>
      </Stack>
    </Stack>
  );
};
