import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Chip, Tooltip } from '@mui/material';

import {
  ChangeLog,
  DocumentType,
  InvoiceStages,
  PaymentChannel,
  PaymentStatus
} from 'openapi';

import {
  DOCUMENT_TYPES_TRANSLATION_KEYS,
  PAYMENT_CHANNELS_TRANSLATION_KEYS,
  PAYMENT_STATUS_STYLES,
  PAYMENT_STATUSES_TRANSLATION_KEYS
} from 'utils/constants/invoices';
import { ChangeLogFields } from 'utils/enums/ChangeLog';

export const buildMessageWithPlaceholders = (
  rawTemplate: string,
  user: string | JSX.Element,
  oldEl?: JSX.Element | string,
  newEl?: JSX.Element | string
) => {
  const userSplit = rawTemplate.split('{user}');
  const userInterleaved: (string | JSX.Element)[] = [];
  userSplit.forEach((chunk, i) => {
    if (i > 0) {
      userInterleaved.push(user);
    }
    userInterleaved.push(chunk);
  });

  const oldInterleaved: (string | JSX.Element)[] = [];
  userInterleaved.forEach((segment) => {
    if (typeof segment === 'string') {
      const parts = segment.split('{old}');
      parts.forEach((p, index) => {
        if (index > 0 && oldEl) {
          oldInterleaved.push(oldEl);
        }
        oldInterleaved.push(p);
      });
    } else {
      oldInterleaved.push(segment);
    }
  });

  const finalInterleaved: (string | JSX.Element)[] = [];
  oldInterleaved.forEach((segment) => {
    if (typeof segment === 'string') {
      const parts = segment.split('{new}');
      parts.forEach((p, index) => {
        if (index > 0 && newEl) {
          finalInterleaved.push(newEl);
        }
        finalInterleaved.push(p);
      });
    } else {
      finalInterleaved.push(segment);
    }
  });

  return finalInterleaved;
};

export const getTranslatedValues = (
  value: string,
  translate: (
    module: string,
    params?: {
      [key: string]: string;
    }
  ) => string
) => {
  if (PAYMENT_STATUSES_TRANSLATION_KEYS[value as PaymentStatus]) {
    return translate(PAYMENT_STATUSES_TRANSLATION_KEYS[value as PaymentStatus]);
  }

  if (PAYMENT_CHANNELS_TRANSLATION_KEYS[value as PaymentChannel]) {
    return translate(
      PAYMENT_CHANNELS_TRANSLATION_KEYS[value as PaymentChannel]
    );
  }

  if (DOCUMENT_TYPES_TRANSLATION_KEYS[value as DocumentType]) {
    return translate(DOCUMENT_TYPES_TRANSLATION_KEYS[value as DocumentType]);
  }

  return value;
};

export const renderValue = (
  value: string,
  group: ChangeLog,
  translate: (
    module: string,
    params?: {
      [key: string]: string;
    }
  ) => string,
  getSeverityColor: (value: InvoiceStages) => {
    color: string;
    backgroundColor: string;
  }
) => {
  if (value === 'true') {
    return (
      <CheckIcon
        sx={{
          color: 'green',
          fontSize: '1.2rem',
          verticalAlign: 'middle'
        }}
      />
    );
  }
  if (value === 'false') {
    return (
      <CloseIcon
        sx={{
          color: 'red',
          fontSize: '1.2rem',
          verticalAlign: 'middle'
        }}
      />
    );
  }
  if (PAYMENT_STATUS_STYLES[value as PaymentStatus]) {
    const { icon, backgroundColor, textColor } =
      PAYMENT_STATUS_STYLES[value as PaymentStatus];
    return (
      <Chip
        key={`${group.timestamp}-old-${value}`}
        label={getTranslatedValues(value, translate)}
        icon={icon}
        size="small"
        sx={{
          backgroundColor,
          color: textColor,
          '& .MuiChip-icon': {
            color: textColor
          }
        }}
      />
    );
  }
  return (
    <Tooltip
      key={`${group.timestamp}-old-${value}`}
      title={getTranslatedValues(value, translate)}
    >
      <Chip
        label={getTranslatedValues(value, translate) || '-'}
        size="small"
        sx={{
          fontSize: '0.8rem',
          fontWeight: 600,
          maxWidth: '13rem',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          color: getSeverityColor(value as InvoiceStages).color,
          backgroundColor: getSeverityColor(value as InvoiceStages)
            .backgroundColor
        }}
      />
    </Tooltip>
  );
};

export const getTranslatedMessageTSX = (
  field: ChangeLogFields,
  oldValue: string,
  newValue: string,
  user: string,
  group: ChangeLog,
  translate: (
    module: string,
    params?: {
      [key: string]: string;
    }
  ) => string,
  getSeverityColor: (value: InvoiceStages) => {
    color: string;
    backgroundColor: string;
  }
) => {
  let rawTemplate = '';
  if (
    field === ChangeLogFields.IsAmountApproved ||
    field === ChangeLogFields.IsCounterpartyApproved ||
    field === ChangeLogFields.IsExpenseTypeApproved
  ) {
    if ((!oldValue || oldValue === 'null') && newValue !== 'true') {
      return null;
    }
    const boolNewVal = newValue === 'true';
    const boolKey = boolNewVal ? 'true' : 'false';
    rawTemplate = translate(`messages.${field}_${boolKey}`, {
      user: '{user}',
      old: '{old}',
      new: '{new}'
    });
  } else if (
    field === ChangeLogFields.IsReceiverVerified ||
    field === ChangeLogFields.IsSupplierVerified ||
    field === ChangeLogFields.IsInvoiceDataVerified ||
    field === ChangeLogFields.IsReimbursement ||
    field === ChangeLogFields.IsBooked
  ) {
    const boolNewVal = newValue === 'true';
    const boolKey = boolNewVal ? 'true' : 'false';
    rawTemplate = translate(`messages.${field}_${boolKey}`, {
      user: '{user}',
      old: '{old}',
      new: '{new}'
    });
  } else if (field === ChangeLogFields.Tags) {
    const tags = newValue ? newValue.split(',') : [];
    if (tags.length > 0) {
      rawTemplate = translate('messages.TAGS_nonEmpty', {
        user: '{user}',
        new: '{new}'
      });
    } else {
      rawTemplate = translate('messages.TAGS_empty', {
        user: '{user}'
      });
    }
  } else if (field === ChangeLogFields.Ibans) {
    const ibans = newValue ? newValue.split(',') : [];
    if (ibans.length > 0) {
      rawTemplate = translate('messages.IBANS', {
        user: '{user}',
        new: '{new}'
      });
    } else {
      rawTemplate = '';
    }
  } else if (field === ChangeLogFields.ExpenseType) {
    if (!newValue || newValue === 'null') {
      rawTemplate = translate('messages.EXPENSE_TYPE_reset', {
        user: '{user}'
      });
    } else {
      rawTemplate = translate('messages.EXPENSE_TYPE', {
        user: '{user}',
        old: '{old}',
        new: '{new}'
      });
    }
  } else if (field === ChangeLogFields.PaymentChannel) {
    if (!newValue || newValue === 'null') {
      rawTemplate = translate('messages.PAYMENT_CHANNEL_removed', {
        user: '{user}'
      });
    } else {
      rawTemplate = translate('messages.PAYMENT_CHANNEL', {
        user: '{user}',
        old: '{old}',
        new: '{new}'
      });
    }
  } else {
    rawTemplate = translate(`messages.${field}`, {
      user: '{user}',
      old: '{old}',
      new: '{new}'
    });
  }

  if (!rawTemplate) return null;

  const userEl = <strong key={`${group.timestamp}-${user}`}>{user}</strong>;
  const oldEl = renderValue(oldValue, group, translate, getSeverityColor);
  const newEl = renderValue(newValue, group, translate, getSeverityColor);

  return buildMessageWithPlaceholders(rawTemplate, userEl, oldEl, newEl);
};
