/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum TrackingAction {
    PAY = 'PAY',
    APPROVE = 'APPROVE',
    VALIDATE = 'VALIDATE',
    BOOK = 'BOOK',
    REJECT = 'REJECT',
}
