import MenuIcon from '@mui/icons-material/Menu';
import { Box, IconButton } from '@mui/material';

import { Navigation } from 'components/Navigation/Navigation';

import { appHeader, headerWrapper } from 'styles/components/HeaderStyle';

interface HeaderProps {
  isMobile: boolean;
  onBurgerClick: () => void;
}

export const Header = ({ isMobile, onBurgerClick }: HeaderProps) => {
  return (
    <Box component="header" sx={appHeader}>
      {isMobile && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            width: '100%'
          }}
        >
          <IconButton
            sx={{
              display: { xs: 'inline-flex', md: 'none' }
            }}
            onClick={onBurgerClick}
          >
            <MenuIcon sx={{ fontSize: '2rem' }} />
          </IconButton>
        </Box>
      )}
      <Box sx={headerWrapper}>
        <Navigation />
      </Box>
    </Box>
  );
};
