import { Close } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';

import { ModalProps } from 'utils/interfaces/ModalProps';

import {
  closeIcon,
  dialogTitle,
  modal,
  iconButton,
  dialogContent
} from 'styles/components/ModalStyle';

import { ModalPortal } from './ModalPortal';

export const Modal = ({
  children,
  headerTitle,
  hide,
  isOpen,
  sx,
  maxWidth = 'md',
  noPaddingY
}: ModalProps) => {
  return isOpen ? (
    <ModalPortal>
      <Dialog open={isOpen} sx={{ ...modal, ...sx }} maxWidth={maxWidth}>
        <DialogTitle sx={dialogTitle}>
          {headerTitle}
          <IconButton onClick={hide} sx={iconButton}>
            <Close sx={closeIcon} />
          </IconButton>
        </DialogTitle>
        <DialogContent
          style={
            noPaddingY ? { height: '100%', paddingBottom: 0 } : dialogContent
          }
        >
          {children}
        </DialogContent>
      </Dialog>
    </ModalPortal>
  ) : null;
};
