/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum InvoiceSortColumns {
    STAGE = 'STAGE',
    SHORT_COMPANY_NAME = 'SHORT_COMPANY_NAME',
    COUNTERPARTY_NAME = 'COUNTERPARTY_NAME',
    INVOICE_NUMBER = 'INVOICE_NUMBER',
    INVOICE_DATE = 'INVOICE_DATE',
    INVOICE_AMOUNT = 'INVOICE_AMOUNT',
    CURRENCY = 'CURRENCY',
    DUE_DATE = 'DUE_DATE',
    INSERTED_BY = 'INSERTED_BY',
    INSERTED_AT = 'INSERTED_AT',
    EXPENSE_TYPE = 'EXPENSE_TYPE',
    PAYMENT_STATUS = 'PAYMENT_STATUS',
}
