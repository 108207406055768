import {
  CircularProgress,
  Paper,
  Box,
  Typography,
  Stack,
  SxProps
} from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridRowSelectionModel,
  GridValueGetterParams
} from '@mui/x-data-grid';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { useCompanyController } from 'api/controllers/CompanyController';
import { useDashboardController } from 'api/controllers/DashboardController';

import { Company } from 'openapi';

import { useTranslations } from 'context/TranslationContext';

import { getBankInfoColumns } from 'utils/helpers/bankInfoHelpers';
import { formatMoney } from 'utils/helpers/moneyHelper';

import { commonDataGridContainerStyle } from 'styles/components/DataGridStyle';
import { invoicesDataGrid } from 'styles/components/InvoicesDataGridStyle';
import { dashboardGridTitle } from 'styles/components/Charts';

interface BankInfoGridProps {
  isOnAllCompanies: boolean;
  sx?: SxProps;
}

interface BankAdditionalInfo {
  company: string;
}

type BankInfo = {
  id?: number;
  isOpenBankingConsented?: boolean;
  balance?: number;
  currency?: string;
} & BankAdditionalInfo;

export const BankInfoGrid = ({ sx, isOnAllCompanies }: BankInfoGridProps) => {
  const { companyId } = useParams();
  const location = useLocation();
  const { translate } = useTranslations();

  const { getBankAccountInfo } = useDashboardController();
  const { getAllCompanies } = useCompanyController();

  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
  const [bankInfo, setBankInfo] = useState<BankInfo[]>();
  const [companies, setCompanies] = useState<Company[]>();
  const requestTokenRef = useRef<number>(0);

  const getCompanies = useCallback(async () => {
    const result = await getAllCompanies();
    setCompanies(result);
  }, [getAllCompanies]);

  const getCompanyBankInfo = useCallback(async () => {
    if (!companies || !companies.length) {
      return;
    }

    const currentToken = Date.now();
    requestTokenRef.current = currentToken;

    const fetchBankDataForCompany = async (id: number, live: boolean) => {
      const response = await getBankAccountInfo(id, live);
      return response.map((item: any) => ({
        ...item,
        company: companies.find((c) => c.id === id)?.name
      })) as BankInfo[];
    };

    if (companyId) {
      const parsedId = Number(companyId);
      const initialData = await fetchBankDataForCompany(parsedId, false);
      if (requestTokenRef.current !== currentToken) return;

      setBankInfo(initialData);

      const liveData = await fetchBankDataForCompany(parsedId, true);
      if (requestTokenRef.current !== currentToken) return;

      setBankInfo(liveData);
      return;
    }

    const allData = await Promise.all(
      companies.map((company) =>
        fetchBankDataForCompany(Number(company.id), false)
      )
    );
    if (requestTokenRef.current !== currentToken) return;

    const flattenedData = allData.flat();
    setBankInfo(flattenedData);

    const allLiveData = await Promise.all(
      companies.map((company) =>
        fetchBankDataForCompany(Number(company.id), true)
      )
    );
    if (requestTokenRef.current !== currentToken) return;
    const flattenedLiveData = allLiveData.flat();
    setBankInfo(flattenedLiveData);
  }, [companyId, companies, getBankAccountInfo]);

  const columns = useMemo(
    () =>
      getBankInfoColumns(isOnAllCompanies, translate)
        .map((column) => {
          if (column.field === 'balance') {
            return {
              ...column,
              valueGetter: (params: GridValueGetterParams) =>
                `${formatMoney(params.row.balance)} ${
                  params.row.currency || ''
                }`
            };
          }
          return column;
        })
        .filter(Boolean) as GridColDef[],
    [translate, isOnAllCompanies]
  );

  useEffect(() => {
    getCompanies();
  }, [getCompanies]);

  useEffect(() => {
    setBankInfo(undefined);
    if (!companies) return;
    getCompanyBankInfo();
  }, [companies, location, getCompanyBankInfo]);

  return (
    <Paper elevation={4} sx={{ ...commonDataGridContainerStyle, ...sx }}>
      <Box display="flex" flexDirection="column" height="100%">
        <Box sx={{ paddingY: 2 }}>
          <Typography sx={dashboardGridTitle} align="left">
            {translate('labels.bankInfo')}
          </Typography>
        </Box>
        <Box flex={1} overflow="auto">
          {bankInfo ? (
            <DataGrid
              columns={columns}
              getRowId={(row) => row.iban || 0}
              rows={bankInfo}
              rowHeight={30}
              sx={{
                ...invoicesDataGrid,
                '& .MuiDataGrid-columnHeaders, & .MuiDataGrid-cell': {
                  cursor: 'default'
                },
                fontSize: '0.6rem',
                '& .MuiDataGrid-columnHeaders': {
                  fontSize: '0.6rem',
                  minHeight: '0 !important',
                  height: '35px'
                },
                '& .MuiDataGrid-cell': {
                  padding: '4px'
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'bold'
                }
              }}
              disableRowSelectionOnClick
              pageSizeOptions={[]}
              hideFooter
              rowSelectionModel={selectedRows}
              onRowSelectionModelChange={setSelectedRows}
              localeText={{
                noRowsLabel: translate('labels.noBankingDataAvailable')
              }}
            />
          ) : (
            <Stack alignItems="center" justifyContent="center" height="100%">
              <CircularProgress />
            </Stack>
          )}
        </Box>
      </Box>
    </Paper>
  );
};
