import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import PaymentIcon from '@mui/icons-material/Payment';
import { green, red, grey, amber } from '@mui/material/colors';

import {
  DocumentType,
  InvoiceQueryFilters,
  InvoiceSortColumns,
  InvoiceStages,
  PaymentChannel,
  PaymentStatus,
  SortOrder
} from 'openapi';

type SeverityColorsType = {
  [key in InvoiceStages]?: {
    color: string;
    backgroundColor: string;
  };
};

interface StatusStyle {
  icon?: React.ReactElement;
  backgroundColor: string;
  textColor: string;
}

export const INITIAL_TOUCHED_RECEIVER_FIELDS = {
  companyRegistrationNumber: true
};

export const INITIAL_TOUCHED_SUPPLIER_FIELDS = {
  counterpartyName: true,
  counterpartyRegistrationNumber: true,
  counterpartyVatNumber: true
};

export const INITIAL_TOUCHED_FIELDS = {
  supplier: true,
  companyRegistrationNumber: true,
  invoiceNumber: true,
  invoiceDate: true,
  dueDate: true,
  vatBase: true,
  invoiceAmount: true
};

export const INITIAL_PRODUCT_ITEM = {
  description: '',
  unitPrice: 0,
  quantity: 0,
  total: 0
};

export const VAT_NUMBER_PREFIX_BULGARIA = 'BG';

export const INVOICE_VERIFICATION_KEYS_IN_ORDER = [
  'isReceiverVerified',
  'isSupplierVerified',
  'areIbansVerified',
  'isInvoiceDataVerified',
  'areProductsVerified'
];
export const SEVERITY_COLORS: SeverityColorsType = {
  [InvoiceStages.DRAFT]: {
    color: 'rgba(0, 0, 0, 0.80)',
    backgroundColor: 'rgba(255,56,56,0.2)'
  },
  [InvoiceStages.VALIDATED]: {
    color: 'rgba(0, 0, 0, 0.80)',
    backgroundColor: 'rgb(255, 185, 33,0.2)'
  },
  [InvoiceStages.APPROVED]: {
    color: 'rgba(0, 0, 0, 0.80)',
    backgroundColor: 'rgba(86,240,0,0.2)'
  },
  [InvoiceStages.FINALIZED]: {
    color: 'rgba(0, 0, 0, 0.80)',
    backgroundColor: 'rgba(45,204,255,0.2)'
  },
  [InvoiceStages.REJECTED]: {
    color: 'rgba(0, 0, 0, 0.80)',
    backgroundColor: 'rgb(27, 27, 27,0.2)'
  }
};

type FieldNames =
  | 'stages'
  | 'shortCompanyNames'
  | 'counterpartyNames'
  | 'expenseTypes'
  | 'insertedBy'
  | 'approvers';

export const ALL_COMPANY_INVOICES_FILTER_FIELDS = [
  { name: 'stages' as FieldNames, translationKey: 'labels.stage' },
  {
    name: 'shortCompanyNames' as FieldNames,
    translationKey: 'labels.shortCompanyName'
  },
  {
    name: 'counterpartyNames' as FieldNames,
    translationKey: 'labels.counterparty'
  },
  { name: 'expenseTypes' as FieldNames, translationKey: 'labels.expenseType' },
  { name: 'insertedBy' as FieldNames, translationKey: 'labels.insertedBy' },
  { name: 'approvers' as FieldNames, translationKey: 'labels.approvers' }
];

export const INVOICES_FILTER_FIELDS = [
  { name: 'stages' as FieldNames, translationKey: 'labels.stage' },
  {
    name: 'counterpartyNames' as FieldNames,
    translationKey: 'labels.counterparty'
  },
  { name: 'expenseTypes' as FieldNames, translationKey: 'labels.expenseType' },
  { name: 'insertedBy' as FieldNames, translationKey: 'labels.insertedBy' },
  { name: 'approvers' as FieldNames, translationKey: 'labels.approvers' }
];

export const FILTERS_DEFAULT_VALUES = {
  stages: [],
  counterpartyNames: [],
  shortCompanyNames: [],
  expenseTypes: [],
  insertedBy: [],
  approvers: [],
  invoiceDateFrom: null,
  invoiceDateTo: null,
  uploadedDateFrom: null,
  uploadedDateTo: null,
  amountFrom: '',
  amountTo: '',
  accounted: '',
  documentType: '',
  documentNumber: '',
  isReimbursement: '',
  tags: [],
  paymentStatuses: [],
  paymentChannel: ''
};

export const INITIAL_SORT = {
  sortBy: InvoiceSortColumns.INSERTED_AT,
  sortOrder: SortOrder.DESC
};

export const INITIAL_INVOICES_FILTERS: InvoiceQueryFilters = {
  ...INITIAL_SORT,
  amount: {},
  invoiceDate: {},
  uploadedDate: {}
};

export const INVOICE_STEPPER_SECTIONS = [
  'labels.initiaion',
  'labels.counterparty',
  'labels.invoiceData',
  'labels.approvals'
];

export const CREATED_DOCUMENT_STEPPER_SECTIONS = [
  'labels.invoiceData',
  'labels.approvals'
];

export const COUNTERPARTY_SEARCH_MIN_CHARACTERS = 2;
export const PAYMENT_CHANNELS_TRANSLATION_KEYS = {
  [PaymentChannel.BANK]: 'labels.bankPayment',
  [PaymentChannel.CASH]: 'labels.cashPayment',
  [PaymentChannel.COMPANY_CARD]: 'labels.cardPayment',
  [PaymentChannel.ALTERNATIVE]: 'labels.alternative'
};
export const DOCUMENT_TYPES_TRANSLATION_KEYS = {
  [DocumentType.CREDIT_NOTE]: 'labels.creditNote',
  [DocumentType.DEBIT_NOTE]: 'labels.debitNote',
  [DocumentType.INVOICE]: 'labels.documentTypeInvoice',
  [DocumentType.NO_DOCUMENT]: 'labels.noDocument',
  [DocumentType.ORDER]: 'labels.order',
  [DocumentType.PROFORMA]: 'labels.proforma',
  [DocumentType.PROTOCOL]: 'labels.protocol'
};
export const PAYMENT_STATUSES_TRANSLATION_KEYS = {
  [PaymentStatus.NOT_PAID]: 'labels.notPaid',
  [PaymentStatus.PREPAID]: 'labels.prepaid',
  [PaymentStatus.MARKED_AS_PAID]: 'labels.markedAsPaid',
  [PaymentStatus.EXPORTED_FOR_PAYMENT]: 'labels.exportedForPayment',
  [PaymentStatus.OPEN_BANKING_WAITING]: 'labels.openBankingWaiting',
  [PaymentStatus.OPEN_BANKING_CONFIRMED]: 'labels.openBankingConfirmed',
  [PaymentStatus.OPEN_BANKING_FAILED]: 'labels.openBankingFailed'
};

export const PAYMENT_STATUS_STYLES: Record<PaymentStatus, StatusStyle> = {
  [PaymentStatus.NOT_PAID]: {
    icon: <ErrorOutlineIcon fontSize="small" />,
    backgroundColor: grey[200],
    textColor: '#000'
  },
  [PaymentStatus.PREPAID]: {
    icon: <CheckCircleOutlineIcon fontSize="small" />,
    backgroundColor: green[50],
    textColor: green[900]
  },
  [PaymentStatus.MARKED_AS_PAID]: {
    icon: <CheckCircleOutlineIcon fontSize="small" />,
    backgroundColor: green[50],
    textColor: green[900]
  },
  [PaymentStatus.EXPORTED_FOR_PAYMENT]: {
    icon: <PaymentIcon fontSize="small" />,
    backgroundColor: green[50],
    textColor: green[900]
  },
  [PaymentStatus.OPEN_BANKING_WAITING]: {
    icon: <HourglassEmptyIcon fontSize="small" />,
    backgroundColor: amber[50],
    textColor: amber[900]
  },
  [PaymentStatus.OPEN_BANKING_CONFIRMED]: {
    icon: <CheckCircleOutlineIcon fontSize="small" />,
    backgroundColor: green[50],
    textColor: green[900]
  },
  [PaymentStatus.OPEN_BANKING_FAILED]: {
    icon: <ErrorOutlineIcon fontSize="small" />,
    backgroundColor: red[50],
    textColor: red[900]
  }
};
