import PendingIcon from '@mui/icons-material/Pending';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import {
  Box,
  Paper,
  Typography,
  Divider,
  Tooltip,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Autocomplete,
  TextField,
  Chip,
  createFilterOptions
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useAdditionalFieldsController } from 'api/controllers/AdditionalFieldsController';
import { useInvoiceController } from 'api/controllers/InvoiceController';

import {
  CurrencyDropDown,
  DocumentType,
  Invoice,
  InvoiceExpenseType,
  InvoiceSection,
  InvoiceStages,
  PaymentChannel,
  PaymentStatus
} from 'openapi';

import { ApprovalButtons } from 'components/forms/InvoiceVerificationForms/ApprovalButtons';
import { LabelValueField } from 'components/shared/LabelField/LabelValueField';
import { Modal } from 'components/shared/Modal/Modal';

import { usePermissions } from 'context/PermissionsContext';
import { useTranslations } from 'context/TranslationContext';

import { useModal } from 'hooks/useModal';

import {
  DOCUMENT_TYPES_TRANSLATION_KEYS,
  PAYMENT_CHANNELS_TRANSLATION_KEYS,
  PAYMENT_STATUS_STYLES,
  PAYMENT_STATUSES_TRANSLATION_KEYS
} from 'utils/constants/invoices';
import { getApprovalStatus } from 'utils/helpers/approvalsHelpers';
import {
  getInvoiceDataDefaultValues,
  getSummaryDataFormValues
} from 'utils/helpers/invoiceHelpers';
import {
  InvoiceDetails,
  InvoiceSectionKey
} from 'utils/interfaces/InvoiceProps';

import {
  approvedIconStyle,
  awaitingApprovalIconStyle,
  rejectedIconStyle
} from 'styles/components/ApprovalsStyle';
import {
  overviewTypographyStyle,
  verificationFormItem
} from 'styles/pages/InvoiceVerificationStyle';

import { EditInvoiceDataForm } from './EditInvoiceDataForm';
import { AdditionalInvoiceFields } from './ReceiverForm';

interface ApprovalsProps {
  currentInvoice: Invoice;
  handleApprove: () => void;
  handleBack?: () => void;
  handleReject: () => void;
  handleReturnToValidated: () => void;
  handleBackFromMiddleStep: () => void;
  handleSaveData: (
    values: Invoice & AdditionalInvoiceFields,
    section: InvoiceSectionKey
  ) => Promise<void>;
}

export const Approvals = ({
  currentInvoice,
  handleBack,
  handleApprove,
  handleReject,
  handleReturnToValidated,
  handleBackFromMiddleStep,
  handleSaveData
}: ApprovalsProps) => {
  const { translate } = useTranslations();
  const { getExpenseTypes, getAllTagsByCompanyId, getCurrencies } =
    useAdditionalFieldsController();
  const { updateExpenseTypeDuringApprovalById } = useInvoiceController();
  const { permissions } = usePermissions();

  const { control, formState, getValues, watch, reset } =
    useForm<InvoiceDetails>({
      mode: 'onChange',
      defaultValues: getInvoiceDataDefaultValues(currentInvoice)
    });

  const [selectedExpenseTypeId, setSelectedExpenseTypeId] = useState<
    number | null
  >(currentInvoice.expenseType?.id || null);
  const [expenseTypeOptions, setExpenseTypeOptions] = useState<
    InvoiceExpenseType[]
  >([]);
  const [tags, setTags] = useState<string[]>(currentInvoice.tags || []);
  const [availableTags, setAvailableTags] = useState<string[]>([]);
  const [currencies, setCurrencies] = useState<CurrencyDropDown[]>();

  const filter = createFilterOptions<string>();

  const {
    isOpen: isEditExpenseTypeModalOpen,
    closeModal: closeEditExpenseTypeModal,
    openModal: openEditExpenseTypeModal
  } = useModal();
  const {
    isOpen: isEditTagsModalOpen,
    closeModal: closeEditTagsModal,
    openModal: openEditTagsModal
  } = useModal();
  const {
    isOpen: isEditSummaryModalOpen,
    closeModal: closeEditSummaryModal,
    openModal: openEditSummaryModal
  } = useModal();

  const renderCounterpartyValue = (): string => {
    const {
      counterpartyName,
      counterpartyRegistrationNumber,
      counterpartyVatNumber
    } = currentInvoice;

    // Both registration number and VAT number are available
    if (counterpartyRegistrationNumber && counterpartyVatNumber) {
      return `${counterpartyName} (${counterpartyRegistrationNumber}, ${counterpartyVatNumber})`;
    }
    // Only registration number is available
    if (counterpartyRegistrationNumber) {
      return `${counterpartyName} (${counterpartyRegistrationNumber})`;
    }
    // Only VAT number is available
    if (counterpartyVatNumber) {
      return `${counterpartyName} (${counterpartyVatNumber})`;
    }
    // Neither registration number nor VAT number are available
    return counterpartyName as string;
  };

  // Expense Type Approval
  const {
    approvalText: expenseTypeApproval,
    approvalValue: expenseTypeApprovalValue
  } = getApprovalStatus(
    currentInvoice.isExpenseTypeApproved || false,
    currentInvoice.expenseTypeApprovedBy,
    currentInvoice.expenseTypeApprovers,
    translate
  );

  // Counterparty Approval
  const {
    approvalText: counterpartyApproval,
    approvalValue: counterpartyApprovalValue
  } = getApprovalStatus(
    currentInvoice.isCounterpartyApproved || false,
    currentInvoice.counterpartyApprovedBy,
    currentInvoice.counterpartyApprovers,
    translate
  );

  // Amount Approval
  const { approvalText: amountApproval, approvalValue: amountApprovalValue } =
    getApprovalStatus(
      currentInvoice.isAmountApproved || false,
      currentInvoice.amountApprovedBy,
      currentInvoice.amountApprovers,
      translate
    );

  const fetchExpenseTypes = useCallback(async () => {
    const expenseTypeOptionValues = await getExpenseTypes(
      Number(currentInvoice.companyId)
    );
    setExpenseTypeOptions(expenseTypeOptionValues);
  }, [getExpenseTypes, currentInvoice]);

  const fetchAvailableTags = useCallback(async () => {
    const tagsResponse = await getAllTagsByCompanyId(
      Number(currentInvoice.companyId)
    );
    setAvailableTags(Array.from(new Set([...tags, ...tagsResponse])));
  }, [getAllTagsByCompanyId, currentInvoice.companyId, tags]);

  const fetchCurrencies = useCallback(async () => {
    const currenciesResponse = await getCurrencies();
    setCurrencies(currenciesResponse);
  }, [getCurrencies, setCurrencies]);

  const handleSaveExpenseType = useCallback(async () => {
    if (
      !selectedExpenseTypeId ||
      !currentInvoice.companyId ||
      !currentInvoice.id
    ) {
      return;
    }

    await updateExpenseTypeDuringApprovalById(
      currentInvoice.companyId,
      currentInvoice.id,
      selectedExpenseTypeId
    );
    closeEditExpenseTypeModal();
  }, [
    selectedExpenseTypeId,
    updateExpenseTypeDuringApprovalById,
    closeEditExpenseTypeModal
  ]);

  const handleSaveTags = useCallback(async () => {
    closeEditTagsModal();
    await handleSaveData(
      {
        tags
      },
      InvoiceSection.SUMMARY
    );
  }, [tags, handleSaveData, currentInvoice, closeEditTagsModal]);

  const handleSaveSummaryData = useCallback(async () => {
    closeEditSummaryModal();
    await handleSaveData(
      getSummaryDataFormValues(
        currentInvoice,
        expenseTypeOptions || [],
        getValues
      ),
      InvoiceSection.SUMMARY
    );
  }, [handleSaveData, currentInvoice, getValues, closeEditSummaryModal]);

  const handleBackForNoDocument = () => {
    if (!handleBack) {
      return;
    }

    handleBackFromMiddleStep();
    handleBack();
  };

  const handleCloseEditSummaryModal = () => {
    closeEditSummaryModal();
    reset();
  };

  const handleCloseEditTagsModal = () => {
    closeEditTagsModal();
    setTags(currentInvoice.tags || []);
  };

  useEffect(() => {
    if (expenseTypeOptions.length) {
      return;
    }

    fetchExpenseTypes();
  }, [fetchExpenseTypes, fetchAvailableTags, fetchCurrencies]);

  useEffect(() => {
    fetchCurrencies();
  }, [fetchCurrencies]);

  useEffect(() => {
    fetchAvailableTags();
  }, [fetchAvailableTags]);

  useEffect(() => {
    setSelectedExpenseTypeId(currentInvoice.expenseType?.id || null);
  }, [currentInvoice]);

  useEffect(() => {
    setAvailableTags((previousTags) =>
      Array.from(new Set([...tags, ...previousTags]))
    );
  }, [tags]);

  useEffect(() => {
    setTags(currentInvoice.tags || []);
  }, [currentInvoice]);

  const { icon, backgroundColor, textColor } =
    PAYMENT_STATUS_STYLES[currentInvoice.paymentStatus as PaymentStatus];
  const label = translate(
    PAYMENT_STATUSES_TRANSLATION_KEYS[
      currentInvoice.paymentStatus as PaymentStatus
    ]
  );
  const paymentChannel =
    PAYMENT_CHANNELS_TRANSLATION_KEYS[
      currentInvoice.paymentChannel as PaymentChannel
    ] &&
    translate(
      PAYMENT_CHANNELS_TRANSLATION_KEYS[
        currentInvoice.paymentChannel as PaymentChannel
      ]
    );

  return (
    <Box sx={{ width: '100%' }}>
      <ApprovalButtons
        handleReject={handleReject}
        handleBack={
          currentInvoice.documentType === DocumentType.NO_DOCUMENT
            ? handleBackForNoDocument
            : handleBack
        }
        handleApprove={handleApprove}
        handleReturnToValidated={handleReturnToValidated}
        currentInvoice={currentInvoice}
      />

      <Paper
        elevation={3}
        sx={{ padding: 1, backgroundColor: '#fefefe', marginTop: 2 }}
      >
        {(currentInvoice.stage === InvoiceStages.APPROVED ||
          currentInvoice.stage === InvoiceStages.FINALIZED) &&
          currentInvoice.canEditSummary && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                sx={{
                  fontSize: '0.7rem',
                  padding: '0rem 0.5rem'
                }}
                variant="outlined"
                size="small"
                onClick={openEditSummaryModal}
              >
                {translate('buttons.edit')}
              </Button>
            </Box>
          )}
        {/* Receiver Information */}
        <LabelValueField
          label={translate('labels.receiver')}
          value={currentInvoice.shortNameCompany || '-'}
        />

        <LabelValueField
          label={
            currentInvoice.isReimbursement
              ? translate('labels.reimburseTo')
              : translate('labels.counterparty')
          }
          value={renderCounterpartyValue()}
        />

        <LabelValueField
          label={translate('labels.documentType')}
          value={
            currentInvoice.documentType
              ? translate(
                  DOCUMENT_TYPES_TRANSLATION_KEYS[currentInvoice.documentType]
                )
              : '-'
          }
        >
          <Tooltip title={paymentChannel}>
            <Chip
              label={label}
              icon={icon}
              sx={{
                backgroundColor,
                color: textColor,
                '& .MuiChip-icon': {
                  color: textColor
                }
              }}
            />
          </Tooltip>
        </LabelValueField>

        <Divider sx={{ my: 2 }} />
        {/* Document and Payment Status */}

        <LabelValueField
          label={translate('labels.invoiceNumberAndDate')}
          value={`${currentInvoice.invoiceNumber || '-'} / ${
            currentInvoice.invoiceDate
          }`}
        />

        <LabelValueField
          label={translate('labels.expenseType')}
          value={
            currentInvoice.expenseType?.isActive
              ? currentInvoice.expenseType?.name || '-'
              : `${currentInvoice.expenseType?.name} ${translate(
                  'labels.deactivatedExpenseTypeValue'
                )}`
          }
        >
          {currentInvoice.stage === InvoiceStages.VALIDATED &&
            permissions.INVOICES.update && (
              <Button
                sx={{
                  fontSize: '0.7rem',
                  padding: '0rem 0.5rem'
                }}
                variant="outlined"
                size="small"
                onClick={openEditExpenseTypeModal}
              >
                {translate('buttons.change')}
              </Button>
            )}
        </LabelValueField>

        <LabelValueField
          label={translate('labels.totalAmount')}
          value={`${currentInvoice.invoiceAmount?.toFixed(2)} ${
            currentInvoice.currency
          } (${translate('labels.vatBase')}: ${
            currentInvoice.vatBase?.toFixed(2) || 0
          } ${currentInvoice.currency})`}
        />

        <Divider sx={{ my: 2 }} />

        <LabelValueField
          label={translate('labels.comment')}
          value={currentInvoice.documentDescription || '-'}
        />

        <LabelValueField
          label={translate('labels.tags')}
          value={currentInvoice.tags?.join(', ') || '-'}
        >
          {currentInvoice.stage === InvoiceStages.VALIDATED &&
            permissions.INVOICES.update && (
              <Button
                sx={{
                  fontSize: '0.7rem',
                  padding: '0rem 0.5rem'
                }}
                variant="outlined"
                size="small"
                onClick={openEditTagsModal}
              >
                {translate('buttons.change')}
              </Button>
            )}
        </LabelValueField>
      </Paper>

      {currentInvoice.stage !== InvoiceStages.REJECTED ? (
        <Paper
          elevation={3}
          sx={{ padding: 1, backgroundColor: '#fefefe', marginTop: 2 }}
        >
          {currentInvoice.isExpenseTypeApproved !== null && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 1
              }}
            >
              {currentInvoice.isExpenseTypeApproved ? (
                <ThumbUpAltIcon sx={approvedIconStyle} />
              ) : (
                <PendingIcon sx={awaitingApprovalIconStyle} />
              )}
              <Box sx={overviewTypographyStyle}>
                <Typography
                  sx={{
                    fontSize: '0.9rem',
                    fontWeight: 600,
                    textAlign: 'left'
                  }}
                >
                  {translate('labels.expenseType')}
                </Typography>
                <Divider sx={{ my: 0.2 }} />
                <Tooltip title={expenseTypeApprovalValue}>
                  <Typography sx={overviewTypographyStyle}>
                    {expenseTypeApproval}
                  </Typography>
                </Tooltip>
              </Box>
            </Box>
          )}

          {currentInvoice.isCounterpartyApproved !== null && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 1
              }}
            >
              {currentInvoice.isCounterpartyApproved ? (
                <ThumbUpAltIcon sx={approvedIconStyle} />
              ) : (
                <PendingIcon sx={awaitingApprovalIconStyle} />
              )}

              <Box sx={overviewTypographyStyle}>
                <Typography
                  sx={{
                    fontSize: '0.9rem',
                    fontWeight: 600,
                    textAlign: 'left'
                  }}
                >
                  {translate('labels.counterparty')}
                </Typography>
                <Divider sx={{ my: 0.2 }} />
                <Tooltip title={counterpartyApprovalValue}>
                  <Typography sx={overviewTypographyStyle}>
                    {counterpartyApproval}
                  </Typography>
                </Tooltip>
              </Box>
            </Box>
          )}

          {currentInvoice.isAmountApproved !== null && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              {currentInvoice.isAmountApproved ? (
                <ThumbUpAltIcon sx={approvedIconStyle} />
              ) : (
                <PendingIcon sx={awaitingApprovalIconStyle} />
              )}
              <Box sx={overviewTypographyStyle}>
                <Typography
                  sx={{
                    fontSize: '0.9rem',
                    fontWeight: 600,
                    textAlign: 'left'
                  }}
                >
                  {translate('labels.amount')}
                </Typography>
                <Divider sx={{ my: 0.2 }} />
                <Tooltip title={amountApprovalValue}>
                  <Typography sx={overviewTypographyStyle}>
                    {amountApproval}
                  </Typography>
                </Tooltip>
              </Box>
            </Box>
          )}
        </Paper>
      ) : (
        <Paper
          elevation={3}
          sx={{ padding: 1, backgroundColor: '#fefefe', marginTop: 2 }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <ThumbDownAltIcon sx={rejectedIconStyle} />
            <Box sx={overviewTypographyStyle}>
              <Typography
                sx={{ fontSize: '0.9rem', fontWeight: 600, textAlign: 'left' }}
              >
                {translate('labels.rejectedBy')}
              </Typography>
              <Divider sx={{ my: 0.2 }} />
              <Tooltip title={expenseTypeApprovalValue}>
                <Typography sx={overviewTypographyStyle}>
                  {currentInvoice.rejectedBy}
                </Typography>
              </Tooltip>
            </Box>
          </Box>
        </Paper>
      )}
      <Modal
        headerTitle={translate('labels.expenseTypeEdit')}
        isOpen={isEditExpenseTypeModalOpen}
        hide={closeEditExpenseTypeModal}
        maxWidth="sm"
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <FormControl sx={verificationFormItem}>
            <InputLabel>{`${translate('labels.expenseType')}*`}</InputLabel>
            <Select
              value={selectedExpenseTypeId || ''}
              sx={{ textAlign: 'left' }}
              label={`${translate('labels.expenseType')}*`}
              onChange={(e) => setSelectedExpenseTypeId(Number(e.target.value))}
              renderValue={(selected) => {
                const selectedOption = expenseTypeOptions.find(
                  (expenseType) => expenseType.id === selected
                ) as InvoiceExpenseType;

                return (
                  <span
                    style={{
                      color:
                        selectedOption && !selectedOption.isActive
                          ? 'gray'
                          : 'inherit'
                    }}
                  >
                    {!selectedOption.isActive
                      ? `${selectedOption.name} ${translate(
                          'labels.deactivatedExpenseTypeValue'
                        )}`
                      : selectedOption.name}
                  </span>
                );
              }}
            >
              {expenseTypeOptions &&
                expenseTypeOptions.map(
                  (expenseType) =>
                    expenseType.isActive && (
                      <MenuItem
                        key={expenseType.id}
                        value={expenseType.id}
                        disabled={!expenseType.isActive}
                      >
                        {expenseType.name}
                      </MenuItem>
                    )
                )}
            </Select>
          </FormControl>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={handleSaveExpenseType} variant="contained">
              {translate('buttons.save')}
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        headerTitle={translate('labels.editTags')}
        isOpen={isEditTagsModalOpen}
        hide={handleCloseEditTagsModal}
        maxWidth="sm"
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <FormControl sx={verificationFormItem}>
            <Autocomplete
              fullWidth
              freeSolo
              multiple
              disableCloseOnSelect
              options={availableTags}
              value={tags}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                if (params.inputValue !== '') {
                  const isExisting = options.some(
                    (option) =>
                      params.inputValue.toLowerCase() === option.toLowerCase()
                  );
                  if (!isExisting) {
                    filtered.push(`Add "${params.inputValue}"`);
                  }
                }

                return filtered;
              }}
              getOptionLabel={(option) => {
                if (option.startsWith('Add "')) {
                  return option;
                }
                return option;
              }}
              onChange={(event, newValue) => {
                const lastValue = newValue[newValue.length - 1];
                if (lastValue && lastValue.startsWith('Add "')) {
                  const newTag = lastValue.slice(5, -1);
                  const updatedTags = [...tags, newTag];
                  setTags(updatedTags);
                } else {
                  const uniqueTags = Array.from(new Set(newValue));
                  setTags(uniqueTags);
                }
              }}
              renderOption={(props, option) => (
                <li {...props}>
                  {option.startsWith('Add "') ? <em>{option}</em> : option}
                </li>
              )}
              renderTags={(value: string[], getTagProps) =>
                value.map((option: string, index: number) => (
                  <Chip
                    sx={{ cursor: 'initial' }}
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                    key={option}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={translate('labels.tags')}
                  placeholder={translate('labels.addATag')}
                />
              )}
            />
          </FormControl>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={handleSaveTags} variant="contained">
              {translate('buttons.save')}
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        headerTitle={translate('labels.editDocument')}
        isOpen={isEditSummaryModalOpen}
        hide={handleCloseEditSummaryModal}
        maxWidth="lg"
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <EditInvoiceDataForm
            currentInvoice={currentInvoice}
            control={control}
            watch={watch}
            availableTags={availableTags}
            setAvailableTags={setAvailableTags}
            currencies={currencies || []}
            expenseTypeOptions={expenseTypeOptions || []}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              disabled={!formState.isValid}
              onClick={handleSaveSummaryData}
              variant="contained"
            >
              {translate('buttons.save')}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
