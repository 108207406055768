/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DocumentChangeLogResponse } from '../models/DocumentChangeLogResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ChangeLogService {

    /**
     * This method returns the list of change logs for a given invoice
     * @param invoiceId The invoice id
     * @returns DocumentChangeLogResponse OK
     * @throws ApiError
     */
    public static getChangeLogsByInvoiceId(
        invoiceId: number,
    ): CancelablePromise<DocumentChangeLogResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/invoices/{invoiceId}/change-logs',
            path: {
                'invoiceId': invoiceId,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

}
