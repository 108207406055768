export enum ChangeLogFields {
  Company = 'COMPANY',
  Counterparty = 'COUNTERPARTY',
  CounterpartyRegistrationNumber = 'COUNTERPARTY_REGISTRATION_NUMBER',
  CounterpartyVatNumber = 'COUNTERPARTY_VAT_NUMBER',
  Currency = 'CURRENCY',
  DocumentDescription = 'DOCUMENT_DESCRIPTION',
  DocumentType = 'DOCUMENT_TYPE',
  DueDate = 'DUE_DATE',
  ExpenseType = 'EXPENSE_TYPE',
  Ibans = 'IBANS',
  InvoiceAmount = 'INVOICE_AMOUNT',
  InvoiceDate = 'INVOICE_DATE',
  InvoiceNumber = 'INVOICE_NUMBER',
  IsAmountApproved = 'IS_AMOUNT_APPROVED',
  IsBooked = 'IS_BOOKED',
  IsCounterpartyApproved = 'IS_COUNTERPARTY_APPROVED',
  IsExpenseTypeApproved = 'IS_EXPENSE_TYPE_APPROVED',
  IsInvoiceDataVerified = 'IS_INVOICE_DATA_VERIFIED',
  IsReceiverVerified = 'IS_RECEIVER_VERIFIED',
  IsReimbursement = 'IS_REIMBURSEMENT',
  IsSupplierVerified = 'IS_SUPPLIER_VERIFIED',
  PaymentChannel = 'PAYMENT_CHANNEL',
  PaymentStatus = 'PAYMENT_STATUS',
  Stage = 'STAGE',
  Tags = 'TAGS',
  VatAmount = 'VAT_AMOUNT',
  VatBase = 'VAT_BASE'
}

export const priorityMap: Record<ChangeLogFields, number> = {
  [ChangeLogFields.Stage]: 0,
  [ChangeLogFields.PaymentStatus]: 1,
  [ChangeLogFields.PaymentChannel]: 2,
  [ChangeLogFields.IsBooked]: 3,
  [ChangeLogFields.IsAmountApproved]: 4,
  [ChangeLogFields.IsCounterpartyApproved]: 5,
  [ChangeLogFields.IsExpenseTypeApproved]: 6,
  [ChangeLogFields.DocumentType]: 7,
  [ChangeLogFields.ExpenseType]: 8,
  [ChangeLogFields.InvoiceAmount]: 9,
  [ChangeLogFields.IsInvoiceDataVerified]: 10,
  [ChangeLogFields.IsSupplierVerified]: 11,
  [ChangeLogFields.IsReceiverVerified]: 12,
  [ChangeLogFields.IsReimbursement]: 13,
  [ChangeLogFields.Company]: 14,
  [ChangeLogFields.Counterparty]: 15,
  [ChangeLogFields.CounterpartyRegistrationNumber]: 16,
  [ChangeLogFields.CounterpartyVatNumber]: 17,
  [ChangeLogFields.InvoiceNumber]: 18,
  [ChangeLogFields.Currency]: 19,
  [ChangeLogFields.VatAmount]: 20,
  [ChangeLogFields.VatBase]: 21,
  [ChangeLogFields.InvoiceDate]: 22,
  [ChangeLogFields.DueDate]: 23,
  [ChangeLogFields.Ibans]: 24,
  [ChangeLogFields.Tags]: 25,
  [ChangeLogFields.DocumentDescription]: 26
};
